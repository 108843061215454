import React from 'react'
import { Link } from 'react-router-dom';
import { Button, Container, Form, Dimmer, Loader, Segment, Checkbox } from 'semantic-ui-react';
//import { useGetJednostkaPwrQuery, useUpdateJednostkaPwrPatchMutation } from '../../redux/JednostkiPwrApi'
import { useGetInfrastrukturaQuery, useUpdateInfrastrukturaPatchMutation } from '../../redux/InfrastrukturaApi'

const InfrastrukturaEdit = (props) => {
    const infrastrukturaId = props.match.params.infrastrukturaId
    //console.log('PracownicyEdit', props.match.params.jednostkaId, props)
    const { data, error, isLoading } = useGetInfrastrukturaQuery(infrastrukturaId)

    return (
        <Container>
            <div>
                {error ? (
                    <>Oh no, there was an error</>
                ) : isLoading ? (
                    <>Loading...</>
                ) : data ? (
                    <div>
                        <h2>Edycja danych jednostki</h2>
                                <InfrastrukturaForm jednostka={data} isLoading={isLoading} />
                    </div>
                ) : null}

            </div>
        </Container>
    )
}

const InfrastrukturaForm = ({ jednostka: infrastruktura, isLoading }) => {
    const [updatePost, result] = useUpdateInfrastrukturaPatchMutation(infrastruktura)
    const handleSubmit = (e) => {
        console.log('InfrastrukturaForm handleSubmit', infrastruktura)
        updatePost(infrastruktura)
    }

    const handleChange = (e) => { }
    const handleCheckboxAuthorityChange = (e) => { }
    const pracownikHasRole = (roleName) => { return false }
    return (
        <div>
            <Button title='Lista' key='back' content='<< Powrót do listy' as={Link} to={`/infrastruktury`} />
            <Button color='green' key='submit' icon='checkmark'
                content='Zapisz' onClick={handleSubmit} disabled={isLoading} />

            <Form className='editForm'>

                <Dimmer active={isLoading} inverted>
                    <Loader />
                </Dimmer>
                <Form.Input id='form-input-name' label='Nazwa' placeholder='Login'
                    name="name" value={infrastruktura.name || ''} readOnly
                />
                <Form.Input id='form-input-activationDate' label='Activation date' placeholder='activationDate'
                    name="activationDate" value={infrastruktura.activationDate || ''} readOnly onChange={handleChange}
                />
                <Form.Input id='form-input-expirationDate' label='Expiration date' placeholder='expirationDate'
                    name="expirationDate" value={infrastruktura.expirationDate || ''} readOnly
                />

                {/* <Message attached='bottom'>
                    <Container textAlign='right'>
                        <ButtonSubmit />
                        <Button color='green' key='submit' icon='checkmark'
                                content='Save' onClick={this.handleSubmit} disabled={this.state.loading} />
                        <Button key='cancel' color='red' icon='remove' content='Cancel' as={Link} to='/users' />
                    </Container>
                </Message> */}
            </Form>
            {Object.entries(infrastruktura).map(item => <div key={item[0]}> {item[0]} {item[1]} </div>)}
        </div>
    )
}

export default InfrastrukturaEdit
