import React from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { PrivateRoute } from '../PrivateRoute'
import MainContent from '../MainContent.js'
import InfrastrukturaList from './InfrastrukturaList'
import InfrastrukturaEdit from './InfrastrukturaEdit'
//import JednostkiEdit from './JednostkiEdit'

const InfrastrukturaRoute = (props) => (
    <Switch>
        <Route path="/infrastruktury/:infrastrukturaId" render={props =>
            <MainContent><InfrastrukturaEdit {...props} /></MainContent>
        } />
        <Route path="/infrastruktury">
            <MainContent><InfrastrukturaList /></MainContent>
        </Route>
    </Switch>
)

export default InfrastrukturaRoute
