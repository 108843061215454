import { createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment, setPage, setTotalCount, infrastrukturaPagingSlice } from './InfrastrukturaPagingSlice'

const baseQuery = fetchBaseQuery({ baseUrl: '/api/rest/' })
const baseQueryExtended = async (args, api, extraOptions) => {
    let baseResult = await baseQuery(args, api, extraOptions)
    const totalCount = baseResult.meta.response.headers.get('X-Total-Count')
    api.dispatch(setTotalCount(totalCount))
    //console.log('baseQueryExtended X-Total', totalCount, api)
    return baseResult
}
//const totalCount = -1

export const infrastrukturaApi = createApi({
    reducerPath: 'infrastrukturaApi',
    totalCount: -2,
    baseQuery: baseQueryExtended,
    endpoints: (builder) => ({
        getInfrastruktury: builder.query({
            query: (page) => `infrastructures?page=${page}&sort=name`,
            transformResponse: (response, meta) => {
                console.log('transformResponse meta', meta)
                //const totalCount = Number(meta.response.headers.get('X-Total-Count'))
                //console.log('transformResponse totalCount', totalCount)
                //this.extra = totalCount
                //dispatch(setTotalCount(totalCount))
                return response
            },
            onCacheEntryAdded(id, params) {
                const { dispatch, getState, extra, requestId, cacheEntryRemoved, cacheDataLoaded, getCacheEntry,
                    updateCachedData } = params
                console.log('onCacheEntryAdded params', params)
                //dispatch(setTotalCount(123))
                },
        }),
        getInfrastrukturyAll: builder.query({
            query: () => `infrastructures-all`,
        }),
        getInfrastruktura: builder.query({
            query: (id) => `infrastructures/${id}`,
        }),

        updateInfrastrukturaPatch: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (patch) => ({
                url: `infrastructures/${patch.id}`,
                method: 'PATCH',
                body: patch,
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['Post'],
            // onQueryStarted is useful for optimistic updates
            // The 2nd parameter is the destructured `MutationLifecycleApi`
            async onQueryStarted(
                arg,
                { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
            ) { },
            // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                }
            ) { },
        }),

    }),
})

export default infrastrukturaApi
export const { useGetInfrastrukturyQuery, useGetInfrastrukturyAllQuery, useGetInfrastrukturaQuery,
    useUpdateInfrastrukturaPatchMutation } = infrastrukturaApi
