import React from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { Button, Container, Form, Dimmer, Loader, Segment, Checkbox } from 'semantic-ui-react';
import _ from 'lodash';
import { useGetPracownikPwrQuery, useUpdatePracownikPwrPatchMutation, usePrzypiszKierownikaDoInfrastrukturJednostkiMutation } 
    from '../../redux/PracownicyPwrApi'
import JednostkaSearch from '../jednostki/JednostkaSearch'

const PracownicyEdit = (props) => {
    const pracownikId = props.match.params.pracownikId
    //console.log('PracownicyEdit', props.match.params.pracownikId, props)
    const { data, error, isLoading } = useGetPracownikPwrQuery(pracownikId)

    return (
        <Container>
            <div>
                {error ? (
                    <>Oh no, there was an error</>
                ) : isLoading ? (
                    <>Loading...</>
                ) : data ? (
                            <div>
                                <h2>Edycja danych pracownika</h2>
                                <PracownikForm pracownikServerState={data} isLoading={isLoading }/>
                            </div>                            
                ): null}

            </div>
        </Container>
    )
}

const role = {
    KOORDYNATOR: 'KOORDYNATOR',
    KIEROWNIK: 'KIEROWNIK',
    APARATUROWIEC: 'APARATUROWIEC',
}

const PracownikForm = ({ pracownikServerState, isLoading}) => {
    const [pracownik, setPracownik] = React.useState(pracownikServerState)
    //console.log('PracownikForm useUpdatePracownikPwrPatchMutation', useUpdatePracownikPwrPatchMutation)
    const [updatePost, result] = useUpdatePracownikPwrPatchMutation(pracownik)
    const jednostkaName = _.get(pracownik, 'jednostkaPwr.name', '')

    const handleSubmit = (e) => {
        console.log('PracownikForm handleSubmit', pracownik)
        updatePost(pracownik)
    }

    const handleChange = (e) => { }
    const handleCheckboxAuthorityChange = (e) => { }
    const pracownikHasRole = (roleName) => {return false}

    const handleChangeJednostka = jednostka => {
        console.log('handleChangeJednostka', jednostka)
        const newPracownik = Object.assign({}, pracownik)
        newPracownik.jednostkaPwr = jednostka
        newPracownik.jednostkaPwrId = jednostka ? jednostka.id : null
        setPracownik(newPracownik)
    }

    const przypiszKierownikaDoInfrastrukturJednostki = () => {

    }

    return (
        <div className=''>
            <Button title='Lista' key='back' content='<< Powrót do listy' as={Link} to={`/pracownicy`} />
            <Button color='green' key='submit' icon='checkmark'
                content='Zapisz' onClick={handleSubmit} disabled={isLoading} />

            <Form className='pracownikForm'>

                <Dimmer active={isLoading} inverted>
                    <Loader />
                </Dimmer>
                <Form.Input id='form-input-name' label='Nazwa' placeholder='Login'
                    name="name" value={pracownik.name || ''} readOnly
                />
                <Form.Input id='form-input-email' label='E-mail' placeholder='E-mail'
                    name="email" value={pracownik.email || ''} readOnly onChange={handleChange}
                />
                <Form.Input id='form-input-jednostkaNad' label='Jednostka nadrzędna' placeholder='E-mail'
                    name="jednostkaNad" value={pracownik.jednostkaNad || ''} readOnly
                />
                <Form.Field key="jed">
                    <label>Jednostka</label>
                    {jednostkaName} {_.get(pracownik, 'jednostkaPwr.symbol', '')}
                    <JednostkaSearch
                        placeholder='wybierz jednostkę...'
                        defaultValue={ jednostkaName }
                        //insertValueOnSelect={true}
                        autoFocus={false}
                        onJednostkaChange={handleChangeJednostka}
                    //readOnly={readOnly}
                    //onProjectPartnerAdd={(e, data) => onInfrastructureChange({ organizationId: data.id, organizationName: data.title })}
                    //onSearchClear={() => onInfrastructureChange({ organizationId: null, organizationName: '' })}
                    />
                </Form.Field>
                <Form.Field key="PrzypiszKierownikaDoInfrastrukturJednostki">
                    <label>Przypisz jako kierownika do infrastruktur jednostki</label>
                    <PrzypiszKierownika pracownikServerState={pracownikServerState} pracownik={pracownik} />
                </Form.Field>
                {/* <Form.Field key="roles">
                    <label>Role</label>
                    <Segment.Group horizontal id='authorities'>
                        <Segment><Checkbox toggle label='Kierownik' name={role.KIEROWNIK} onChange={handleCheckboxAuthorityChange}
                            checked={pracownikHasRole(role.AUTHORIZING)} /></Segment>
                        <Segment><Checkbox toggle label='Koordynator' name={role.KOORDYNATOR} onChange={handleCheckboxAuthorityChange}
                            checked={pracownikHasRole(role.KOORDYNATOR)} /></Segment>
                        <Segment><Checkbox toggle label='Aparaturowiec' name={role.APARATUROWIEC} onChange={handleCheckboxAuthorityChange}
                            checked={pracownikHasRole(role.APARATUROWIEC)} /></Segment>
                    </Segment.Group>
                </Form.Field > */}
                {/* 
                <Form.Input id='form-input-firstName' label='Imię' placeholder='First Name'
                    name="firstName" value={user.firstName || ''} readOnly onChange={handleChange}
                />
                <Form.Input id='form-input-lastName' label='Nazwisko' placeholder='Last Name'
                    name="lastName" value={user.lastName || ''} readOnly onChange={handleChange}
                />
                <Form.Field >
                    <label>Status konta</label>
                    <Segment.Group horizontal>
                        <Segment><Checkbox toggle label='Aktywne' name='activated' onChange={handleCheckboxChange}
                            checked={user.activated} readOnly /></Segment>
                    </Segment.Group>
                </Form.Field > */}

                {/* <Message attached='bottom'>
                    <Container textAlign='right'>
                        <ButtonSubmit />
                        <Button color='green' key='submit' icon='checkmark'
                                content='Save' onClick={this.handleSubmit} disabled={this.state.loading} />
                        <Button key='cancel' color='red' icon='remove' content='Cancel' as={Link} to='/users' />
                    </Container>
                </Message> */}
                {
                    process.env.NODE_ENV == 'development'
                    &&
                    Object.entries(pracownik).map(item => {
                        if (typeof item[1] === 'object') {
                            return <div key={item[0]}> {item[0]} {JSON.stringify(item[1])} </div>
                        } else {
                            return <div key={item[0]}> {item[0]} {item[1]} </div>
                        }

                    })
                }
            </Form>
        </div>
    )
}

const PrzypiszKierownika = (props) => {
    const { isLoading, pracownikServerState } = props
    //console.log('PrzypiszKierownika pracownikServerState', pracownikServerState)
    const [przypiszKierownika, przypiszKierownikaResult] = usePrzypiszKierownikaDoInfrastrukturJednostkiMutation()

    const handlePrzypisz = () => {
        przypiszKierownika({ idPracownik: pracownikServerState .id })
    }

    return (
        <>
            <Button color='blue' key='submit' icon='checkmark'
                content='Przypisz' onClick={handlePrzypisz} disabled={isLoading} />
        </>
    )
}

export default PracownicyEdit
