import React from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { Button, Container, Form, Dimmer, Loader, Segment, Checkbox } from 'semantic-ui-react';
import { useGetJednostkaPwrQuery, useUpdateJednostkaPwrPatchMutation } from '../../redux/JednostkiPwrApi'

const JednostkiEdit = (props) => {
    const jednostkaId = props.match.params.jednostkaId
    //console.log('PracownicyEdit', props.match.params.jednostkaId, props)
    const { data, error, isLoading } = useGetJednostkaPwrQuery(jednostkaId)

    return (
        <Container>
            <div>
                {error ? (
                    <>Oh no, there was an error</>
                ) : isLoading ? (
                    <>Loading...</>
                ) : data ? (
                            <div>
                                <h2>Edycja danych jednostki</h2>
                                <JednostkaForm jednostka={data} isLoading={isLoading }/>
                            </div>                            
                ): null}

            </div>
        </Container>
    )
}

const role = {
    KOORDYNATOR: 'KOORDYNATOR',
    KIEROWNIK: 'KIEROWNIK',
    APARATUROWIEC: 'APARATUROWIEC',
}

const JednostkaForm = ({ jednostka, isLoading}) => {
    //console.log('JednostkaForm useUpdateJednostkaPwrPatchMutation', useUpdateJednostkaPwrPatchMutation)
    const [updatePost, result] = useUpdateJednostkaPwrPatchMutation(jednostka)
    const handleSubmit = (e) => {
        console.log('JednostkaForm handleSubmit', jednostka)
        updatePost(jednostka)
    }

    const handleChange = (e) => { }
    const handleCheckboxAuthorityChange = (e) => { }
    const pracownikHasRole = (roleName) => {return false}
    return (
        <div>
            <Button title='Lista' key='back' content='<< Powrót do listy' as={Link} to={`/jednostki`} />
            <Button color='green' key='submit' icon='checkmark'
                content='Zapisz' onClick={handleSubmit} disabled={isLoading} />

            <Form className='editForm'>

                <Dimmer active={isLoading} inverted>
                    <Loader />
                </Dimmer>
                <Form.Input id='form-input-name' label='Nazwa' placeholder='Login'
                    name="name" value={jednostka.name || ''} readOnly
                />
                <Form.Input id='form-input-activationDate' label='Activation date' placeholder='activationDate'
                    name="activationDate" value={jednostka.activationDate || ''} readOnly onChange={handleChange}
                />
                <Form.Input id='form-input-expirationDate' label='Expiration date' placeholder='expirationDate'
                    name="expirationDate" value={jednostka.expirationDate || ''} readOnly
                />

                {/* <Message attached='bottom'>
                    <Container textAlign='right'>
                        <ButtonSubmit />
                        <Button color='green' key='submit' icon='checkmark'
                                content='Save' onClick={this.handleSubmit} disabled={this.state.loading} />
                        <Button key='cancel' color='red' icon='remove' content='Cancel' as={Link} to='/users' />
                    </Container>
                </Message> */}
            </Form>
            {Object.entries(jednostka).map(item => <div key={item[0]}> {item[0]} {item[1]} </div>)}
        </div>
    )
}

export default JednostkiEdit
