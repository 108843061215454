import React, { useState } from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import { Button, Confirm, Icon, Table } from 'semantic-ui-react'
import { useSelector, useDispatch } from 'react-redux'
import { added, deleted, edited } from '../../../redux/orgWspolpracaSlice'
import OrganizationApi from '../OrganizationApi';
import * as utils from '../../../lib/utils.js'
import OrgWspolpracaEdit from './OrgWspolpracaEdit'

const OrgWspolpracaList = (organization) => {
    const canEdit = true
    const orgWspolpraca = useSelector((state) => state.orgWspolpraca.listaWspolpracy)
    console.log('orgWspolpraca', orgWspolpraca)
    const dispatch = useDispatch()
    const listaWspolpracy = orgWspolpraca
    const [editMode, setEditMode] = useState(false)

    const dodajWspolprace = () => {
        const nowaWpolpraca = {
            id: 2,
            data_start: '2022-02-23',
            time_start: '10:25',
            data_end: '2022-02-23',
            time_end: '14:50',
            title: "Spotkanie",
            description: "opis przykładowego zdarzenia"
        }
        dispatch(added(nowaWpolpraca))
    }
    const zachowajWspolprace = (wspolpraca) => {
        if (wspolpraca) {
            if (wspolpraca.id) {
                dispatch(edited(wspolpraca))
            } else {
                dispatch(added(wspolpraca))
            }
        }
        setEditMode(false)
    }
    const edytujWspolprace = () => {
        
    }
    const toggleEditMode = () => {
        setEditMode(!editMode)
    }

    const DeleteOrgWspolpracaButton = ({wspolpraca}) => {
        const [isOpen, setOpen] = useState(false)
        const usun = () => {
            dispatch(deleted(wspolpraca))
            setOpen(false)
        }
        return (
            <>
                <Button icon='delete' title='Usuń' onClick={() => setOpen(true)} />
                <Confirm
                    open={isOpen}
                    onCancel={() => setOpen(false)}
                    onConfirm={usun}
                    header='Potwierdź usunięcie' content={'Czy usunąć współpracę?'}
                />
            </>
        )
    }
    const TabelaWspolpracy = () => {
        return (
            <Table celled >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Data</Table.HeaderCell>
                        <Table.HeaderCell>Tytuł</Table.HeaderCell>
                        <Table.HeaderCell>Opis</Table.HeaderCell>
                        <Table.HeaderCell>Akcje</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        listaWspolpracy.map(wspolpraca => {
                            return (
                                <Table.Row key={wspolpraca.id}>
                                    <Table.Cell className='preLine'>
                                        {/* <Link to={`/organizations/${OrganizationApi.organizationId(organization)}`}
                                            title='Preview'>
                                            {organization.nameEnglish}
                                        </Link> */}
                                        {wspolpraca.data_start}
                                    </Table.Cell>
                                    <Table.Cell className='preLine'>
                                        {wspolpraca.title}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {wspolpraca.description}
                                    </Table.Cell>
                                    <Table.Cell textAlign='center'>
                                        {wspolpraca.id}
                                        <Button.Group basic>
                                            {canEdit && <Button icon='edit' title='Edit'  />}
                                            {canEdit && <DeleteOrgWspolpracaButton wspolpraca={wspolpraca} organization={organization} />}
                                        </Button.Group>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })
                    }
                </Table.Body>
            </Table>
        )
    }

    return(
        <div style={{ minHeight: "200px" }}>
            <div>
                <Button content='Dodaj współpracę' icon='add' labelPosition='left'
                    onClick={toggleEditMode} />
                <Button content='załóż testową współpracę' icon='add' labelPosition='left'
                    onClick={dodajWspolprace} />
            </div>
            {
                editMode
                ?
                    <OrgWspolpracaEdit wspolpraca={{}} organization={organization} 
                        zachowajWspolprace={zachowajWspolprace} />
                :
                    <TabelaWspolpracy />
            }
        </div>
    )

}

const OrgWspolpracaEdycja = () => {

    return (
        <div>

        </div>
    )
}

export default OrgWspolpracaList
