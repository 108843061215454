import { userService } from '../user/user.service.js';
import _ from 'lodash';

export default class InfrastructureApi {
    static infrastructureId = infrastructure => Number(_.last(infrastructure._links.self.href.split('/')))

    static isNew = infrastructure => !infrastructure.updateTimestamp;

    static canEdit = infrastructure => {
        const loggedUser = userService.loggedUser();
        const loggedUserEmail = _.get(loggedUser, 'email', '-')
        const opiekunEdytuje = _.get(infrastructure, 'opiekun.email', 'brak opiekun') === loggedUserEmail
        const aparaturowiecEdytuje = _.get(infrastructure, 'aparaturowiec.email', 'brak aparaturowiec') === loggedUserEmail
        const pracownicyInfrastruktury = _.get(infrastructure, 'pracownikToInfrastructures', [])
        const kierownikEdytuje = pracownicyInfrastruktury.filter(
            osoba => osoba.role === 'kierownik' && osoba.pracownikPwr.email === loggedUserEmail)
            .find(x => x !== undefined)

        return userService.isAdmin() || userService.isAuthorizing() || userService.isKoordynator()
            || (loggedUser && loggedUser.id === infrastructure.userOwnerId)
            || (loggedUser && loggedUser.id === infrastructure.userCreateId)
            || InfrastructureApi.isNew(infrastructure)
            || (InfrastructureApi.czyEtapOpiekun(infrastructure) && opiekunEdytuje)
            || (InfrastructureApi.czyEtapAparaturowiec(infrastructure) && aparaturowiecEdytuje) 
            || (InfrastructureApi.czyEtapKierownik(infrastructure) && kierownikEdytuje);
    }

    static czyEtapOpiekun = infrastructure => {
        return infrastructure.akceptacjaAutora !== 1
    }
    static czyEtapKierownik = infrastructure => {
        return infrastructure.akceptacjaAutora === 1 && infrastructure.akceptacjaOpisu !== 1
    }
    static czyEtapAparaturowiec = infrastructure => {
        return infrastructure.akceptacjaAutora === 1 && infrastructure.weryfikacjaAparaturowca !== 1
    }

    /**
     * Status infrastruktury - tekstowy opis.
     * @param {Object} infrastructure - obiekt infrastruktura.
     * @return {string} Tekstowy opis statusu infrastruktury.
     */
    static statusInfrastruktury = infrastructure => {
        let statusText = 'Oczekuje na zatwierdzenie os. odpowiedzialnej'
        if (infrastructure.akceptacjaAutora === 1) {
            statusText = 'Sprawdzone przez osobę odpowiedzialną'
        }
        if (infrastructure.weryfikacjaAparaturowca === 1) {
            statusText = 'Sprawdzone przez osobę ewidencjonującą'
        }
        if (infrastructure.akceptacjaOpisu === 1) {
            statusText = 'Zatwierdzone przez kierownika jednostki'
        }
        if (infrastructure.akceptacjaTresci === 1) {
            statusText = 'Zatwierdzone przez koordynatora CIB'
        }

        return statusText
    }

    static opiekunInfrastrukturyFromInfrastructureRestEntity = infrastructureRest => {
        let opiekun = null
        if (Array.isArray(infrastructureRest.pracownikToInfrastructures)) {
            const opiekunowie = infrastructureRest.pracownikToInfrastructures.filter(rel => rel.role === 'autor')
                .map(rel => rel.pracownikPwr)
            opiekun = opiekunowie.find(x => x !== undefined)
        }
        return opiekun
    }

    static aparaturowiecInfrastrukturyFromInfrastructureRestEntity = infrastructureRest => {
        let aparaturowiec = null
        if (Array.isArray(infrastructureRest.pracownikToInfrastructures)) {
            const opiekunowie = infrastructureRest.pracownikToInfrastructures.filter(rel => rel.role === 'aparaturowiec')
                .map(rel => rel.pracownikPwr)
            aparaturowiec = opiekunowie.find(x => x !== undefined)
        }
        return aparaturowiec
    }

    static edytujeAutor = infrastructure => {
        let loggedUser = userService.loggedUser();
        const czyOpiekun = _.get(infrastructure, 'opiekun.email', 'brak opiekun') === _.get(loggedUser, 'email', '-')
        return czyOpiekun
    }

    static edytujeAparaturowiec = infrastructure => {
        const loggedUserEmail = _.get(userService.loggedUser(), 'email', '-')
        const czyAparaturowiec = _.get(infrastructure, 'aparaturowiec.email', 'brak aparaturowiec') === loggedUserEmail
        return czyAparaturowiec
    }

    static edytujeKierownik = infrastructure => {
        const loggedUserEmail = _.get(userService.loggedUser(), 'email', '-')
        const pracownicyInfrastruktury = _.get(infrastructure, 'pracownikToInfrastructures', [])
        const kierownikEdytuje = pracownicyInfrastruktury.filter(
            osoba => osoba.role === 'kierownik' && osoba.pracownikPwr.email === loggedUserEmail)
            .find(x => x !== undefined)
        return kierownikEdytuje
    }
}