import { createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import _ from 'lodash';
import { setTotalCount } from './PracownicyPagingSlice'

// const initialState = {
//     pracownicy: [],
// }

// export const pracownicyPwrSlice = createSlice({
//     name: 'pracownicyPwr',
//     initialState,
//     reducers: {
//     },
// })

//export const { added, deleted, edited } = pracownicyPwrSlice.actions
//export default pracownicyPwrSlice.reducer


export const pracownicyPwrApi = createApi({
    reducerPath: 'pracownicyPwrApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/rest/' }),
    endpoints: (builder) => ({
        getPracownicyPwr: builder.query({
            query: (page) => `pracownik-pwrs?page=${page}`,
        }),
        getPracownicyPwrSearch: builder.query({
            query: ({ page=-1, size=20, sort='name', searchRequest }) => ({
                url: `pracownik-pwrs-search?page=${page}&sort=${sort}&size=${size}`,
                method: 'POST',
                body: searchRequest,
            }),
            onCacheEntryAdded(id, params) {
                const { dispatch, getState, extra, requestId, cacheEntryRemoved, cacheDataLoaded, getCacheEntry,
                    updateCachedData } = params
                // console.log('onCacheEntryAdded params', params)
                cacheDataLoaded.then(() => {
                    const cacheEntry = getCacheEntry()
                    const totalElements = _.get(cacheEntry, 'data.totalElements', -1)
                    dispatch(setTotalCount(totalElements))
                    // console.log('onCacheEntryAdded totalElements', totalElements)
                    // console.log('onCacheEntryAdded getCacheEntry', cacheEntry)
                })
            },
        }),
        getPracownicyPwrAll: builder.query({
            query: () => `pracownik-pwrs-all`,
        }),
        getPracownikPwr: builder.query({
            query: (id) => `pracownik-pwrs/${id}`,
        }),

        updatePracownikPwrPatch: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (patch) => ({
                url: `pracownik-pwrs/${patch.id}`,
                method: 'PATCH',
                body: patch,
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['Post'],
            // onQueryStarted is useful for optimistic updates
            // The 2nd parameter is the destructured `MutationLifecycleApi`
            async onQueryStarted(
                arg,
                { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
            ) { },
            // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                }
            ) { },
        }),
        przypiszKierownikaDoInfrastrukturJednostki: builder.mutation({
            query: ({ idPracownik, symbolJednostki }) => ({
                url: `przypisz-kierownika-do-infrastruktur-jednostki?idPracownik=${idPracownik}&symbolJednostki=${symbolJednostki}`,
                method: 'POST',
            }),
        }),
    }),
})

export default pracownicyPwrApi
export const { useGetPracownicyPwrQuery, useGetPracownicyPwrSearchQuery, useGetPracownicyPwrAllQuery, useGetPracownikPwrQuery,
    useUpdatePracownikPwrPatchMutation, usePrzypiszKierownikaDoInfrastrukturJednostkiMutation } = pracownicyPwrApi
