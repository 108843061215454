import React, { useState, useEffect } from 'react';
import { Button, Form, Message, Segment, Modal } from 'semantic-ui-react'
import { Dimmer, Loader, Container, Checkbox } from 'semantic-ui-react'
import { Route, Link, Switch, Redirect, withRouter, useHistory } from 'react-router-dom'
import { modules, pathToApi } from '../../lib/connectionConst'

const authorities= {
    KOORDYNATOR: 'ROLE_KOORDYNATOR',
    AUTHORIZING: 'ROLE_AUTHORIZING',
    ADMIN: 'ROLE_ADMIN',
    APARATUROWIEC: 'APARATUROWIEC',
}

export const UserEdit = (props) => {
    const { id } = props
    const [isLoading, setIsLoading] = useState(false)
    const [user, setUser] = useState({})
    const history = useHistory()
    //console.log('props', props)
    
    useEffect(() => {
        if(id)
            loadUser()
    }, [id])

    async function loadUser() {
        setIsLoading(true)
        const jsonName = pathToApi(modules.USER_REST) + `/${id}`
        const response = await fetch(jsonName);
        const myJson = await response.json();

        setUser(myJson)
        setIsLoading(false)
    }

    ///////////////////////////////////////////////////////

    const setUserField = (fieldName, fieldValue) => {
        let u = { ...user };
        u[fieldName] = fieldValue;
        setUser(u);
    }

    const userHasAuthority = authorityName => {
        if (!user || !user.authorities) return false
        return user.authorities.some(el => el.name === authorityName)
    }

    const setUserAuthority = (authorityName, booleanValue) => {
        if (user.authorities.some(el => el.name === authorityName) == booleanValue) return

        let u = { ...user }
        if (booleanValue) {
            u.authorities = user.authorities.concat({ "name": authorityName })
        } else {
            u.authorities = user.authorities.filter(el => el.name !== authorityName)
        }
        //console.log('setUserAuthority', u)
        setUser(u);
    }
    ///////////////////////////////////////////////////////


    const handleChange = (e) => {
        const { name, value } = e.target;
        setUserField(name, value)
    }

    const handleCheckboxChange = (e, data) => {
        const { name, checked } = data;
        setUserField(name, checked)
    }

    const handleCheckboxAuthorityChange = (e, data) => {
        const { name, checked } = data;
        setUserAuthority(name, checked)
    }

    const handleSubmit = (e) => {
        setIsLoading(true)

        const doWyslaniaJson = JSON.stringify(user)
        fetch(pathToApi(modules.USER_REST) + `/${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json' // 'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: doWyslaniaJson
        })
            .then(response => {
                if (!response.ok) {
                    return Promise.reject();
                }
                return response.json()
            })
            .then(json => {
                if (json.is_request_successful === false) {
                    const error_message = json.error_message
                    const errorCause = json.cause
                    return Promise.reject({ error_message, errorCause })
                }
                const fromServer = json
                history.push('/users')
                //promiseHandler(fromServer)
                setIsLoading(false)
            })
            //.catch(error => errorHandler(error))
    }

    const ButtonSubmit = withRouter(({ history }) => (
        <Button color='green' key='submit' icon='checkmark'
            content='Save' onClick={(e) => { handleSubmit(e, history) }} disabled={isLoading} />
    ))

    return (
        <Container>
            <Button title='Lista' content='<< Powrót do listy' as={Link} to={`/users`} />
            <Button color='green' key='submit' icon='checkmark'
                content='Zapisz' onClick={handleSubmit} disabled={isLoading} />
            <Form className='editForm'>

                <Dimmer active={isLoading} inverted>
                    <Loader />
                </Dimmer>
                <Form.Input id='form-input-login' label='Login' placeholder='Login'
                    name="login" value={user.login || 'brak'} readOnly
                />
                <Form.Input id='form-input-firstName' label='Imię' placeholder='First Name'
                    name="firstName" value={user.firstName || ''} readOnly onChange={handleChange}
                />
                <Form.Input id='form-input-lastName' label='Nazwisko' placeholder='Last Name'
                    name="lastName" value={user.lastName || ''} readOnly onChange={handleChange}
                />
                <Form.Input id='form-input-email' label='E-mail' placeholder='E-mail'
                    name="email" value={user.email || ''} readOnly onChange={handleChange}
                />
                {/* <Label as='a' Basic>Overview</Label> */}
                <Form.Field >
                    <label>Role</label>
                    <Segment.Group horizontal id='authorities'>
                        {/* <Segment><Checkbox toggle label='Kierownik' name={authorities.AUTHORIZING} onChange={handleCheckboxAuthorityChange} 
                            checked={userHasAuthority(authorities.AUTHORIZING)} /></Segment> */}
                        <Segment><Checkbox toggle label='Koordynator' name={authorities.KOORDYNATOR} onChange={handleCheckboxAuthorityChange} 
                            checked={userHasAuthority(authorities.KOORDYNATOR)} /></Segment>
                        {/* <Segment><Checkbox toggle label='Aparaturowiec' name={authorities.APARATUROWIEC} onChange={handleCheckboxAuthorityChange}
                            checked={userHasAuthority(authorities.APARATUROWIEC)} /></Segment> */}
                        <Segment><Checkbox toggle label='Admin' name={authorities.ADMIN} onChange={handleCheckboxAuthorityChange} 
                            checked={userHasAuthority(authorities.ADMIN)} /></Segment>
                    </Segment.Group>
                </Form.Field >
                <Form.Field >
                    <label>Status konta</label>
                    <Segment.Group horizontal>
                        <Segment><Checkbox toggle label='Aktywne' name='activated' onChange={handleCheckboxChange} 
                            checked={user.activated} readOnly /></Segment>
                    </Segment.Group>
                </Form.Field >

                {/* <Message attached='bottom'>
                    <Container textAlign='right'>
                        <ButtonSubmit />
                        <Button color='green' key='submit' icon='checkmark'
                                content='Save' onClick={this.handleSubmit} disabled={this.state.loading} />
                        <Button key='cancel' color='red' icon='remove' content='Cancel' as={Link} to='/users' />
                    </Container>
                </Message> */}
            </Form>
            
        </Container>
    )
}