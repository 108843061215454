import React from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { PrivateRoute } from '../PrivateRoute'
import MainContent from '../MainContent.js'
import PremisesList from './PremisesListAll'
//import PracownicyEdit from './PracownicyEdit'
//import './Pracownicy.css'

const PremisesRoute = (props) => (
    <Switch>
        {/* <Route path="/premises/:pracownikId" render={props =>
            <PracownicyEdit {...props} />
        } /> */}
        <Route path="/premises">
            <PremisesList />
        </Route>
    </Switch>
)

export default PremisesRoute
