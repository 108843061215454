import { createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// const initialState = {
//     pracownicy: [],
// }

// export const pracownicyPwrSlice = createSlice({
//     name: 'pracownicyPwr',
//     initialState,
//     reducers: {
//     },
// })

//export const { added, deleted, edited } = pracownicyPwrSlice.actions
//export default pracownicyPwrSlice.reducer


export const jednostkiPwrApi = createApi({
    reducerPath: 'jednostkiPwrApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/rest/' }),
    endpoints: (builder) => ({
        getJednostkiPwr: builder.query({
            query: (page) => `jednostka-pwrs?page=${page}`,
        }),
        getJednostkiPwrAll: builder.query({
            query: () => `jednostka-pwrs-all`,
        }),
        searchJednostkiPwr: builder.query({
            query: (searchtext) => `jednostka-pwrs-search?searchtext=${searchtext}`,
        }),
        getJednostkaPwr: builder.query({
            query: (id) => `jednostka-pwrs/${id}`,
        }),

        updateJednostkaPwrPatch: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (patch) => ({
                url: `jednostka-pwrs/${patch.id}`,
                method: 'PATCH',
                body: patch,
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['Post'],
            // onQueryStarted is useful for optimistic updates
            // The 2nd parameter is the destructured `MutationLifecycleApi`
            async onQueryStarted(
                arg,
                { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
            ) { },
            // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                }
            ) { },
        }),

    }),
})

export default jednostkiPwrApi
export const { useGetJednostkiPwrQuery, useGetJednostkiPwrAllQuery, useSearchJednostkiPwrQuery, useGetJednostkaPwrQuery,
    useUpdateJednostkaPwrPatchMutation } = jednostkiPwrApi
