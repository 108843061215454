import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Button, Container, Icon, Checkbox } from 'semantic-ui-react'
import Dropdown from '../inputs/Dropdown.js'
import DropdownTree from '../inputs/DropdownTree.js'
import * as utils from '../../lib/utils.js'
import { UserManualButton } from './Infrastructure'
import client from '../../lib/client.js'
import JednostkaSearch from '../jednostki/JednostkaSearch'
import PracownikSearch from '../pracownicy/PracownicySearch'

const InfrastructureSearch = props => {
    const { infrastructureSearchFields, onInfrastructureSearchFieldsChange, searchInfrastructure, searchHash } = props
    const searchFieldsChanged = searchHash !== utils.hashCodeObject(infrastructureSearchFields)
    const [countryOptions, setCountryOptions] = useState([]);
    useEffect(() => {
        //loadCountries()
    }, []);
    const loadCountries = () => {
        return client('/api/dictionary/country/dropdown')
            .then(response => {
                setCountryOptions(response.entity)
            })
            .finally(() => {
                //this.setState({ countryLoading: false });
            });
    }
    const handleChangeJednostka = jednostka => {
        console.log('handleChangeJednostka', jednostka)
        onInfrastructureSearchFieldsChange({ 'jednostka': jednostka.symbol })
    }
    const handleChangePracownik = pracownik => {
        console.log('handleChangePracownik', pracownik)
        onInfrastructureSearchFieldsChange({'pracownik': pracownik.email})
    }

    return (
        <div className='infrastructure-search'>
            <Form size='tiny'>
                <Form.Input
                    label='Szukane słowo'
                    placeholder='Free search'
                    name='freeSearch'
                    value={infrastructureSearchFields.freeSearch || ''}
                    onChange={(e, data) => utils.handleInputChange(e, onInfrastructureSearchFieldsChange)}
                />
                {/* <Dropdown
                    onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                    value={infrastructureSearchFields.country}
                    label='Country'
                    name='country'
                    options={countryOptions}
                /> */}
                {/* <div className='stepInfrastructure'>
                    <DropdownTree
                        name='infrastructureAdditiveManufacturing'
                        label='Infrastructure Type Additive Manufacturing'
                        onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                        dataUrl='/data/organization_infrastructure_additive_manufacturing.json'
                        value={utils.multiselectStringToArray(infrastructureSearchFields.infrastructureAdditiveManufacturing)}
                    />

                    <DropdownTree
                        name='infrastructureIndustry40'
                        label='Infrastructure Type Industry 4.0'
                        onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                        dataUrl='/data/organization_infrastructure_industry_40.json'
                        value={utils.multiselectStringToArray(infrastructureSearchFields.infrastructureIndustry40)}
                    />

                    <DropdownTree
                        name='infrastructureMicroNanoManufacturing'
                        label='Infrastructure Type Micro/Nano Manufacturing'
                        onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                        dataUrl='/api/dictionary/infrastructure_micro_nano_manufacturing/dropdown_tree'
                        value={utils.multiselectStringToArray(infrastructureSearchFields.infrastructureMicroNanoManufacturing)}
                    />
                </div> */}
                {/* <Form.Input
                    label='Osoba odpowiedzialna'
                    placeholder='Osoba odpowiedzialna'
                    name='tetaOsobaUzytkownik'
                    value={infrastructureSearchFields.tetaOsobaUzytkownik || ''}
                    onChange={(e, data) => utils.handleInputChange(e, onInfrastructureSearchFieldsChange)}
                /> */}
                {/* <Dropdown
                    upward
                    onChange={(e, dropdownData) => utils.handleDropdownChange(dropdownData, onInfrastructureSearchFieldsChange)}
                    value={infrastructureSearchFields.possibilitiesToRent}
                    label='Możliwości użycia'
                    name='possibilitiesToRent'
                    dataUrl='/data/infrastructure_possibilities_to_rent.json'
                /> */}
                <Form.Field>
                    <label>Moja infrastruktura</label>
                    <Checkbox toggle label='Infrastruktura przypisana do mnie' name='mojaInfrastruktura'
                        checked={infrastructureSearchFields.mojaInfrastruktura == 1 || false}
                        className='authorized'
                        //onChange={(event, data) => onInfrastructureChange({ akceptacjaOpisu: data.checked ? 1 : 0 })}
                        onChange={(e, data) => onInfrastructureSearchFieldsChange({ mojaInfrastruktura: data.checked ? 1 : 0 })} />
                </Form.Field>
                <Form.Field key="jed">
                    <label>Jednostka</label>
                    {/* {jednostkaName} {_.get(pracownik, 'jednostkaPwr.symbol', '')} */}
                    <JednostkaSearch
                        placeholder='wybierz jednostkę...'
                        defaultValue={''}
                        //insertValueOnSelect={true}
                        autoFocus={false}
                        onJednostkaChange={handleChangeJednostka}
                        //readOnly={readOnly}
                        //onProjectPartnerAdd={(e, data) => onInfrastructureChange({ organizationId: data.id, organizationName: data.title })}
                        onSearchClear={() => onInfrastructureSearchFieldsChange({ 'jednostka': '' })}
                    />
                </Form.Field>
                {
                    process.env.NODE_ENV == 'development'
                    &&
                    <div>
                            <Form.Field key="prac">
                                <label>Pracownik</label>
                                <PracownikSearch
                                    placeholder='wybierz pracownika...'
                                    defaultValue={''}
                                    //insertValueOnSelect={true}
                                    autoFocus={false}
                                    onPracownikChange={handleChangePracownik}
                                //readOnly={readOnly}
                                onSearchClear={() => onInfrastructureSearchFieldsChange({'pracownik': ''})}
                                />
                            </Form.Field>
                        </div>
                }
                <Container textAlign='center' as='h5' fluid className='search-header'>
                    <Button primary icon style={{ margin: '0 15px' }}
                        onClick={searchInfrastructure} disabled={!searchFieldsChanged}>
                        <Icon name='search' /> Wyszukaj infrastrukturę
                    </Button>
                    {/* <UserManualButton /> */}
                </Container>
            </Form>
        </div>
    )
}

export default InfrastructureSearch