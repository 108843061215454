import React, { useState, useEffect } from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import axios from 'axios'
import AppMenu from './AppMenu'
import './PwrLogin.css'
import { userService } from './user/user.service.js';

const SuccessLogin = ({ setLoggedUser }) => {
    const [loading, setloading] = useState(true);
    const [authorized, setAuthorized] = useState(false);
    const [loggedAs, setLoggedAs] = useState({});
    const storage = sessionStorage;

    useEffect(() => {
        const getLoggedUser = async () => {
            const result = await axios.post('/authentication_principals/logged_user');
            setloading(false)
            //console.log('LoggedUser', result.data)
            if (result.data && result.data.id) {
                setAuthorized(true)
                const user = result.data
                setLoggedAs(user)
                storage.setItem('user', JSON.stringify(user));
                setLoggedUser && setLoggedUser(user)
            }
        }
        getLoggedUser()

        if (process.env.NODE_ENV == 'development') {
            const loggedUser = userService.loggedUser();
            //console.log('loggedUser', loggedUser)
            if (loggedUser) {
                setLoggedAs(loggedUser)
                setAuthorized(true)
            }
        }
    }, []);

    return (
        <main className='siteContent'>
            {authorized && <AppMenu />}
        
            <div id="pwr_login">
                {loading && <h2>trwa uwierzytelnienie...</h2>}
                {authorized &&
                    <div>
                        Zalogowano
                        <h2>{loggedAs.firstName} {loggedAs.lastName}</h2>
                        <h3>{loggedAs.email}</h3>
                        <Button as={Link} to={'/infrastructures'} color='red' content='Przejdź do infrastruktury' />
                    </div>
                }
            </div>
        </main>
    )

}

export default SuccessLogin