import React from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { PrivateRoute } from '../PrivateRoute'
import MainContent from '../MainContent.js'
import PracownicyList from './PracownicyList'
import PracownicyEdit from './PracownicyEdit'
import './Pracownicy.css'

const PracownicyRoute = (props) => (
    <Switch>
        <Route path="/pracownicy/:pracownikId" render={props =>
            <MainContent><PracownicyEdit {...props} /></MainContent>
        } />
        <Route path="/pracownicy">
            <MainContent><PracownicyList /></MainContent>
        </Route>


        {/* <Route path="/infrastructures/register" exact render={props =>
            <InfrastructureCreateForm {...props} authRequired onSubmit={onCreate} />
        } />
        <Route path="/infrastructures/:infrastructureId" render={props =>
            <InfrastructureCreateForm {...props} onSubmit={onUpdate}
                title={<span><Icon name='edit' /> Infrastruktura</span>} />
        } /> */}
    </Switch>
)

export default PracownicyRoute
