import React from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { Icon, Label, Menu, Table, Button } from 'semantic-ui-react'
import { useGetPracownicyPwrPageAllQuery } from '../../redux/PracownicyPwrPage'
import { useGetJednostkiPwrAllQuery } from '../../redux/JednostkiPwrApi'
        

const JednostkiList = () => {
    const { data, error, isLoading } = useGetJednostkiPwrAllQuery()

    return (
        <div className='infrastructure'>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
            ) : data ? (
                        <div className='projectList'>
                            <TabelaJednostek listaJednostek={data} />
                        </div>
            ) : null}
        </div>
    )
}


const TabelaJednostek = ({listaJednostek}) => {
    return (
    <Table celled>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>Nazwa</Table.HeaderCell>
                <Table.HeaderCell>Symbol</Table.HeaderCell>
                <Table.HeaderCell>Activation date</Table.HeaderCell>
                <Table.HeaderCell>Expiration date</Table.HeaderCell>
            </Table.Row>
        </Table.Header>

        <Table.Body>
                {listaJednostek.map(jednostka => <Table.Row key={jednostka.id}>
                <Table.Cell>
                    <Link to={`/jednostki/${jednostka.id}`}
                        title='Preview'>
                        {jednostka.name}
                    </Link>
                </Table.Cell>
                <Table.Cell>{jednostka.symbol}</Table.Cell>
                <Table.Cell>{jednostka.activationDate}</Table.Cell>
                <Table.Cell>{jednostka.expirationDate}</Table.Cell>
            </Table.Row>
            )}
        </Table.Body>

        <Table.Footer>
            <Table.Row>
                <Table.HeaderCell colSpan='3'>
                    {/* {data && data.length}
        {Object.entries(data[1]).map(item => <span> {item } </span>)}
    <Menu floated='right' pagination>
        <Menu.Item as='a' icon>
            <Icon name='chevron left' />
        </Menu.Item>
        <Menu.Item as='a'>1</Menu.Item>
        <Menu.Item as='a'>2</Menu.Item>
        <Menu.Item as='a'>3</Menu.Item>
        <Menu.Item as='a'>4</Menu.Item>
        <Menu.Item as='a' icon>
            <Icon name='chevron right' />
        </Menu.Item>
    </Menu> */}
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    </Table>
    )
}

export default JednostkiList
