import React, { useState } from 'react'
import { Calendar, Views } from 'react-big-calendar'
import moment from "moment";
import localizer from 'react-big-calendar/lib/localizers/globalize'
import globalize from 'globalize'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { Button, Form, Modal, Popup, Message, Card } from 'semantic-ui-react'

const DragAndDropCalendar = withDragAndDrop(Calendar)
const globalizeLocalizer = localizer(globalize)
require('globalize/lib/cultures/globalize.culture.pl')


const przykladoweWydarzenia = [
    {
        id: 1,
        start: moment().toDate(),
        end: moment().add(1, "days").toDate(),
        title: "Przykładowe wydarzenie"
    },
    {
        id: 2,
        start: moment().date(10).hour(10).toDate(),
        end: moment().date(10).hour(12).toDate(),
        title: "Spotkanie"
    }
]

let Kalendarz = ({ localizer, style }) => {
    const [events, setEvents] = useState(przykladoweWydarzenia);
    const [draggedEvent, setDraggedEvent] = useState(null);
    const [viewedEvent, setViewedEvent] = useState(null);
    const [showEvent, setShowEvent] = useState(false)


    const newEvent = (event) => {
        const { start, end } = event
        const title = window.prompt('New Event name')
        if (title) {
            setEvents([
                ...events,
                {
                    start,
                    end,
                    title,
                },
            ])
        }
    }
    const viewEvent = (event) => {
        console.log('viewEvent', event)
        //alert(event.title)
        setViewedEvent(event)
    }

    const removeEvent = (event) => {
        const newTab = [...events]
        const index = newTab.findIndex(element => element.id === event.id)
        if (index > -1) {
            newTab.splice(index, 1);
        }
        setEvents(newTab)
    }
    const saveEvent = (event) => {
        console.log('saveEvent', event)
        const newEvents = events.map(existingEvent => {
            return existingEvent.id == event.id
                ? { ...existingEvent, start: event.start, end: event.end, title: event.title }
                : existingEvent
        })
        setEvents(newEvents)
    }

    const handleDragStart = event => {
        setDraggedEvent(event)
    }
    const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {

        let allDay = event.allDay

        if (!event.allDay && droppedOnAllDaySlot) {
            allDay = true
        } else if (event.allDay && !droppedOnAllDaySlot) {
            allDay = false
        }

        const nextEvents = events.map(existingEvent => {
            return existingEvent.id == event.id
                ? { ...existingEvent, start, end, allDay }
                : existingEvent
        })

        setEvents(nextEvents)

        // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
    }

    const dragFromOutsideItem = () => {
        return draggedEvent
    }

    return (
        <>
            <Message
                header='Wydarzenie'
                content={'brak szczegółów.' + JSON.stringify(viewedEvent)}
            />
            <div id='kalendarz'>
                <DragAndDropCalendar
                    events={events}
                    localizer={globalizeLocalizer}
                    style={{ height: "70vh" }}
                    defaultDate={new Date()}
                    defaultView={Views.MONTH}
                    messages={{ next: "Następny", previous: "Poprzedni", today: "Dzisiaj", month: "Miesiąc", week: "Tydzień", day: "Dzień" }}
                    culture={'pl'}
                    selectable
                    onSelectEvent={viewEvent}
                    onSelectSlot={newEvent}

                    onDragStart={console.log}
                    handleDragStart={handleDragStart}
                    onEventDrop={moveEvent}
                    popup={true}
                    resizable

                // dayPropGetter={customDayPropGetter}
                // slotPropGetter={customSlotPropGetter}
                // components={{
                //     event: Event,
                //     agenda: {
                //         event: EventAgenda,
                //     },
                // }}
                />
            </div>
            {!!viewedEvent &&
            <EventView event={viewedEvent} hideEvent={() => setViewedEvent(null)} removeEvent={removeEvent} saveEvent={saveEvent} />
            }
        </>
    )
}

const EventView = ({ event, hideEvent, removeEvent, saveEvent}) => {
    //console.log('EventView event', event)
    //console.log('EventView removeEvent', removeEvent)
    const [start, setStart] = useState(event.start.toISOString().substring(0, 16));
    const [end, setEnd] = useState(event.end.toISOString().substring(0, 16));
    const mountNode = document.querySelector("#kalendarz")
    const onSave = () => {
        saveEvent({ id: event.id, start: new Date(start), end: new Date(end), title: event.title})
        hideEvent()
    }
    const onRemove = () => {
        removeEvent(event)
        hideEvent()
    }
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        //console.log('handleInputChange', name, value)
        switch (name){
            case "event-start": setStart(value); break;
            case "event-end": setEnd(value); break;
        }
    }

    return (
        <Modal open={!!event} onClose={hideEvent} dimmer='inverted' centered mountNode={mountNode}>
            {/* <Modal.Header>{event.title}</Modal.Header>
            <Modal.Content>
                <input type="datetime-local" id="event-start"
                                name="event-start" value={start} onChange={handleInputChange} />
            </Modal.Content>
            <Modal.Content>
                <input type="datetime-local" id="event-end"
                                name="event-end" value={end} onChange={handleInputChange} />
            </Modal.Content> */}
            <Modal.Content>
                <Card>
                    <Card.Content>
                        <Card.Header>{event.title}</Card.Header>
                        <Card.Meta>{event.start.toISOString()}</Card.Meta>
                        <Card.Meta>{event.end.toISOString()} % {new Date(start).toISOString()}</Card.Meta>
                        <Card.Meta>{start}</Card.Meta>
                        <Card.Description>
                            <input type="datetime-local" id="event-start"
                                name="event-start" value={start} onChange={handleInputChange} />
                            <input type="datetime-local" id="event-end"
                                name="event-end" value={end} onChange={handleInputChange} />
                        </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                        <div className='ui three buttons'>
                            <Button basic color='green' onClick={onSave}>
                                Zapisz
                            </Button>
                            <Button basic color='red' onClick={hideEvent}>
                                Anuluj
                            </Button>
                            <Button basic color='red' onClick={onRemove}>
                                Usuń
                            </Button>
                        </div>
                    </Card.Content>
                </Card>
            </Modal.Content>

        </Modal>
    )
}

export default Kalendarz