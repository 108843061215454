import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import counterReducer from './counterSlice'
import orgWspolpracaReducer from './orgWspolpracaSlice'
import infrastrukturaPagingReducer from './InfrastrukturaPagingSlice'
import { pracownicyPwrPageApi } from './PracownicyPwrPage'
import { pracownicyPwrApi  } from './PracownicyPwrApi'
import pracownicyPagingReducer from './PracownicyPagingSlice'
import { jednostkiPwrApi } from './JednostkiPwrApi'
import { infrastrukturaApi } from './InfrastrukturaApi'
import { permisesApi } from './PermisesApi'

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        orgWspolpraca: orgWspolpracaReducer,
        infrastrukturaPaging: infrastrukturaPagingReducer,
        pracownicyPaging: pracownicyPagingReducer,

        [pracownicyPwrPageApi.reducerPath]: pracownicyPwrPageApi.reducer,
        [pracownicyPwrApi.reducerPath]: pracownicyPwrApi.reducer,
        [jednostkiPwrApi.reducerPath]: jednostkiPwrApi.reducer,
        [infrastrukturaApi.reducerPath]: infrastrukturaApi.reducer,
        [permisesApi.reducerPath]: permisesApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(pracownicyPwrPageApi.middleware)
            .concat(pracownicyPwrApi.middleware)
            .concat(jednostkiPwrApi.middleware)
            .concat(infrastrukturaApi.middleware)
            .concat(permisesApi.middleware)
})
setupListeners(store.dispatch)