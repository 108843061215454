import React from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { Icon, Label, Menu, Table, Button, Pagination } from 'semantic-ui-react'
import { useGetInfrastrukturyQuery, useGetInfrastrukturyAllQuery } from '../../redux/InfrastrukturaApi'
import { useSelector, useDispatch } from 'react-redux'
import { setPage } from '../../redux/InfrastrukturaPagingSlice'


const InfrastrukturyList = () => {
    const page = useSelector((state) => state.infrastrukturaPaging.page)
    const totalCount = useSelector((state) => state.infrastrukturaPaging.totalCount)

    const infrastrukturyQuery = useGetInfrastrukturyQuery(page)
    const { data, error, isLoading } = infrastrukturyQuery
    const dispatch = useDispatch()

    const pageSize = 20
    let totalPages = 0
    if (totalCount > 0) {
        totalPages = Math.floor(totalCount / pageSize)
    }

    const onPageChange = (event, data) => {
        dispatch(setPage(data.activePage - 1))
    }

    return (
        <div className='projectList infrastructure'>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
            ) : data ? (
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Nazwa</Table.HeaderCell>
                            <Table.HeaderCell>tetaOsobaUzytkownik</Table.HeaderCell>
                            <Table.HeaderCell>tetaOsobaEwidencja</Table.HeaderCell>
                            <Table.HeaderCell>tetaJednostkaUzytkowania</Table.HeaderCell>
                            <Table.HeaderCell>tetaDataPrzyjecia</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            data.map(infrastruktura =>
                                <Table.Row key={infrastruktura.id}>
                                    <Table.Cell>
                                        <Link to={`/infrastruktury/${infrastruktura.id}`}
                                            title='Preview'>
                                            {infrastruktura.name}
                                        </Link>
                                    </Table.Cell>
                                    <Table.Cell>{infrastruktura.tetaOsobaUzytkownik}</Table.Cell>
                                    <Table.Cell>{infrastruktura.tetaOsobaEwidencja}</Table.Cell>
                                    <Table.Cell>{infrastruktura.tetaJednostkaUzytkowania}</Table.Cell>
                                    <Table.Cell>{infrastruktura.tetaDataPrzyjecia}</Table.Cell>
                                </Table.Row>
                            )
                        }
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                                    <Table.HeaderCell textAlign='center' colSpan='5'>
                                        <Pagination
                                            firstItem={null}
                                            lastItem={null}
                                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                                            totalPages={totalPages}
                                            onPageChange={onPageChange}
                                            activePage={page + 1}
                                        />
                                        <div>
                                            {/* {data && data.length}
                            {Object.entries(data[1]).map(item => <span> {item } </span>)} */}
                                            Rekordów {totalCount} {/* page {page} totalPages {totalPages} */}
                                        </div>

                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            ) : null}
        </div>
    )
}

export default InfrastrukturyList