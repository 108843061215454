import React from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { PrivateRoute } from '../PrivateRoute'
import MainContent from '../MainContent.js'
import JednostkiList from './JednostkiList'
import JednostkiEdit from './JednostkiEdit'

const JednostkiRoute = (props) => (
    <Switch>
        <Route path="/jednostki/:jednostkaId" render={props =>
            <MainContent><JednostkiEdit {...props} /></MainContent>
        } />
        <Route path="/jednostki">
            <MainContent><JednostkiList /></MainContent>
        </Route>
    </Switch>
)

export default JednostkiRoute
