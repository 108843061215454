import React from 'react'
import { Icon } from 'semantic-ui-react'
import './AppFooter.css'

const AppFooter = () => (
    <footer className='appFooter'>
        {/* <a href='https://www.facebook.com/SYNERGYINTERREG/' target='_blank' rel="noopener noreferrer" title='Facebook'>
            <Icon name='facebook' size='large' />
        </a>
        <a href='https://www.linkedin.com/groups/8654532' target='_blank' rel="noopener noreferrer" title='LinkedIn'>
            <Icon name='linkedin square' size='large' />
        </a>
        <a href='https://www.linkedin.com/in/synergyinterreg/' target='_blank' rel="noopener noreferrer" title='LinkedIn'>
            <Icon name='linkedin square' size='large' />
        </a> */}
    </footer>
)

export default AppFooter