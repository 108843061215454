import React from 'react'
import { Link } from 'react-router-dom'
import { Dropdown } from 'semantic-ui-react'
import { userService } from './../user/user.service.js';

class UserMenuCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoginForm: false,
        }
    }

    handleLogoutClick = () => this.props.loggedUser.setLoggedUser(null);
    handleLoginClick = () => this.props.loggedUser.handleShowLoginForm();

    render() {
        const loggedUser = userService.loggedUser();

        const loggedUserLabel =
            loggedUser != null
                ?
                <span>Zalogowany <span className='loggedUserLabel'>{loggedUser.email}</span></span>
                :
                <span />

        return (
            userService.isAuthenticated()
                ?
                <Dropdown item trigger={loggedUserLabel} className='item userMenuCard'>
                    <Dropdown.Menu>
                        <Dropdown.Header>{loggedUser.firstName} {loggedUser.lastName}</Dropdown.Header>
                        {/* <Dropdown.Item as={Link} to='/change_password'>Change password</Dropdown.Item> */}
                        <Dropdown.Item onClick={this.handleLogoutClick}>Wyloguj</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                :
                <span>
                    <Dropdown item text='Not logged' className='userMenuCard'>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.handleLoginClick}>Zaloguj</Dropdown.Item>
                            <Dropdown.Item as={Link} to='/register_new_account'>Register new account</Dropdown.Item>
                            <Dropdown.Item as={Link} to='/pwr-login'>pwr-login</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </span>
        );
    }

}

export default UserMenuCard