import React from 'react'
import { Container, Header, Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import MainContent from './MainContent.js'
import AppMenu from './AppMenu'
import { PrivateRoute, PrivateRouteWithoutLoginMonit } from './PrivateRoute'

import './HomePage.css'

const HomePage = () => (
    <MainContent>
        <PrivateRouteWithoutLoginMonit>
            <AppMenu />
        </PrivateRouteWithoutLoginMonit>
        <div className=''>
            <Container className='synergyText'>
                {/* <Header as='h1' className='sheader'>CIB Tool</Header> */}
                <Container text className=''>
                    <p className='opis_main_page'>
                        Szanowni Państwo,<br/>
                        Rozpoczynamy projekt katalogowania zasobów sprzętowych znajdujących się na wyposażeniu Politechniki Wrocławskiej.<br />
                        {/* Link do strony infrastruktury:<br />
                        infrastruktura.pwr.edu.pl<br />
                        Logowanie do systemu następuję poprzez logowanie domenowe PWr.<br /> */}
                        <br />
                        Prosimy o zalogowanie się i sprawdzenie czy na liście pojawiły się urządzenia znajdujące się w 
                        Państwa laboratoriach i będące pod Państwa opieką. Prosimy także o uzupełnienie ich opisu oraz wpisanie 
                        możliwości ich praktycznego zastosowania, co przyczyni się do wzbogacenia biznesowej oferty 
                        Politechniki Wrocławskiej. Zaznaczamy, iż aparatura wybrana do uruchomienia systemu wybrana była 
                        wg kryteriów zawężających jej ilość (abyśmy mogli przeprowadzić testy wdrożeniowe), więc może się zdarzyć, 
                        że nie wszystkie pozycje aparatury przez Państwa posiadanej są uwzględnione w wykazie. 
                        Informacyjnie system docelowo będzie zawierał wszystkie pozycje aparatury będące w posiadaniu 
                        Politechniki Wrocławskiej.
                        <br /><br />
                        Jeżeli mają Państwo sugestie nt. merytorycznych aspektów systemu bądź będą Państwo chcieli, aby Państwa aparatura, 
                        mająca wysoki potencjał komercjalizacyjny, pojawiła się w systemie, prosimy o kontakt pod 
                        adresem: <span className='email'>infrastruktura@pwr.edu.pl</span>
                        {/* <br /><br />
                        Jeżeli mają Państwo uwagi co do działania strony, prosimy o ich zgłaszanie do Pana Arkadiusza Sienkiewicza, 
                        na adres <span className='email'>arkadiusz.sienkiewicz@pwr.edu.pl</span> */}

                    </p>
                </Container>
                <PrivateRoute>
                    <Container textAlign='center' className='logged_container'>
                        {/* <Button as={Link} to="/organizations" size='large'>
                            CRM
                        </Button> */}
                        <Button as={Link} to="/infrastructures" size='large' color='red'>
                            Przejdź do Infrastruktury
                        </Button>
                    </Container>
                </PrivateRoute>
            </Container>
        </div>
    </MainContent>
)


export default HomePage