import React, { useState } from 'react';
import { Modal, Form, Container, Message, Button, Icon } from 'semantic-ui-react';
import { infrastructureRequestFields as fields } from './InfrastructureRequestFields';
import { getInvalidFields, ToastErrorMessage } from '../../../lib/validation.js';
import { FormContent } from './FormContent';
import moment from 'moment';
import { toast } from 'react-toastify';
import { userService } from '../../user/user.service.js';
import { UserContext } from '../../../user-context';
import { Link } from 'react-router-dom';
import { LoginModal, ForgotPasswordButton, RegisterNewAccountButton } from '../../user/LoginPage';
import client from '../../../lib/client.js';
import { modules, pathToApi } from '../../../lib/connectionConst'
import InfrastructureApi from '../InfrastructureApi';

const InfrastructureRequestForm = props => {
    const { infrastructure, open, handleClose, authRequired } = props;
    const [infrastructureRequest, setInfrastructureRequest] = useState({});
    const [invalidFields, setInvalidFields] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSubmit = evt => {
        if (validateForm().length === 0) {
            setLoading(true);

            let loggedUser = userService.loggedUser();
            if (loggedUser && loggedUser.id) {
                infrastructureRequest.requestFromUserId = loggedUser.id;
            }

            infrastructureRequest.infrastructureId = InfrastructureApi.infrastructureId(infrastructure);
            infrastructureRequest.requestTo = infrastructure.responsiblePersonEmail;

            client({
                method: 'POST',
                path: pathToApi(modules.INFRASTRUCTURE_REQUESTS),
                entity: infrastructureRequest,
                headers: { 'Content-Type': 'application/json' }
            })
                .then(response => {
                    toast.success(<span>Request sent to {infrastructureRequest.requestTo}</span>);
                    handleClose(evt);
                })
                .catch(error => {
                    setLoading(false)
                    toast.error(<ToastErrorMessage
                        message={'Failed to send infrastructure request: ' + error.entity.message}
                        violations={error.entity.violations} />, { autoClose: false }
                    )
                })
        }
    }

    const handleInfrastructureRequestChange = changes => {
        setInfrastructureRequest({ ...infrastructureRequest, ...changes })
    }

    const validateForm = () => {
        const invalid = getInvalidFields(infrastructureRequest, fields);
        const { possibleUseStartDate, possibleUseEndDate } = infrastructureRequest;

        if (possibleUseStartDate && possibleUseEndDate) {
            const currentDate = moment().format('YYYY-MM-DD');
            if (new Date(possibleUseStartDate) > new Date(possibleUseEndDate)) {
                invalid.push({ label: 'Possible time slot of the infrastructure use start/end date incorrect' })
            }
            if (new Date(possibleUseStartDate) < new Date(currentDate)) {
                invalid.push({ label: 'Possible start time slot of the infrastructure use can not be in the past' })
            }
        }

        setInvalidFields(invalid);
        return invalid;
    }

    const isFormValid = () => invalidFields.length === 0;

    const handleDismissValidationErrors = () => setInvalidFields([]);

    if (authRequired && !userService.isAuthenticated()) {
        return (
            <UserContext.Consumer>
                {({ setLoggedUser }) => (
                    <LoginModal setLoggedUser={setLoggedUser} open={open} onClose={handleClose}>
                        <ForgotPasswordButton />
                        <RegisterNewAccountButton />
                    </LoginModal>
                )}
            </UserContext.Consumer>
        )
    }

    return (
        <Modal open={open} onClose={handleClose} dimmer='inverted' closeOnDimmerClick={false} className='infrastructureContact'>
            <Modal.Header className='ui'>
                <Icon name='mail outline' />
                <div>
                    Contact <span className='nameEditLabel'>{infrastructure.responsiblePerson} &lt;{infrastructure.responsiblePersonEmail}&gt;</span>
                    <br />
                    Infrastructure <span className='nameEditLabel'> {infrastructure.name}</span>
                </div>
            </Modal.Header>
            <Form className='attached segment' error={!isFormValid()} loading={loading}>
                <Message className='validationErrors'
                    error
                    size='small'
                    header='The following fields are invalid or missing'
                    list={invalidFields.map(o => o.label).filter(o => o !== undefined)}
                    onDismiss={handleDismissValidationErrors}
                />
                <Modal.Content>
                    <FormContent
                        infrastructure={infrastructure}
                        infrastructureRequest={infrastructureRequest}
                        onChange={handleInfrastructureRequestChange}
                        invalidFields={invalidFields}
                    />
                </Modal.Content>
            </Form>
            <Message attached='bottom'>
                <Container textAlign='right'>
                    <Button key='submit' color='green' icon='checkmark' content='Submit' onClick={handleSubmit} loading={loading} />
                    <Button key='cancel' color='red' icon='remove' content='Cancel' onClick={handleClose} />
                </Container>
            </Message>
        </Modal>
    )
}

export default InfrastructureRequestForm;