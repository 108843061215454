import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Message, Modal, Container } from 'semantic-ui-react'
import { userService } from './user.service.js';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        // reset login status
        userService.logout();

        this.state = {
            username: '',
            password: '',
            error: '',
            isLoading: false,
        }
    }

    handleChange = e => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    setError = error => this.setState({ error, isLoading: false })

    handleLogin = evt => {
        this.setState({ error: '' });
        const { username, password } = this.state;

        if (username && password) {
            userService.login(username, password, this.props.setLoggedUser, this.setError);
            this.setState({ isLoading: true });
        }
    }

    render() {
        const { username, password, error, isLoading } = this.state;
        const { success } = this.props;

        return (
            <Form className='login-form' error={error !== ''} success>
                <Message error size='small' header={error} />
                <Message success size='small' header={success} />

                <Form.Input
                    fluid icon='user'
                    iconPosition='left'
                    placeholder='User name'
                    name='username' value={username} onChange={this.handleChange}
                    autoFocus
                />
                <Form.Input
                    fluid icon='lock'
                    iconPosition='left'
                    placeholder='Password'
                    type='password'
                    name='password' value={password} onChange={this.handleChange}
                />

                <Button color='teal' fluid size='large' content='Login'
                    loading={isLoading} disabled={isLoading}
                    onClick={this.handleLogin}
                />
            </Form>
        )
    }
}

const LoginModal = props => {
    const { setLoggedUser, success, ...rest } = props;
    return (
        <Modal dimmer='inverted' size='tiny' {...rest}>
            <Modal.Header>Logowanie</Modal.Header>

            <Modal.Content>
                <LoginPage setLoggedUser={setLoggedUser} success={success} />
            </Modal.Content>

            <Message attached='bottom'>
                <Container textAlign='right'>
                    {props.children}
                </Container>
            </Message>
        </Modal>
    )
}

export const ForgotPasswordButton = props => 
    <Button content='Forgot password' primary as={Link} to='/forgot-password' {...props} />

export const RegisterNewAccountButton = props => 
    <Button content='Register new account' primary as={Link} to='/register_new_account' {...props} />

export { LoginPage, LoginModal };