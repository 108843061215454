import { isBlank, maxSize } from '../../lib/validation.js'

export const organizationFields = {
    nameEnglish: {
        label: 'Nazwa',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    nameOriginalLanguage: {
        label: 'Nazwa (in original language)',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    contactPerson: {
        label: 'Osoba kontaktowa',
        sizeMax: 255,
        get validations() { return [maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    address: {
        label: 'Adres',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    zipCode: {
        label: 'Kod pocztowy',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    city: {
        label: 'Miejscowość',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    region: {
        label: 'Region',
        step: 1
    },
    country: {
        label: 'Kraj',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    phone: {
        label: 'Telefon',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    email: {
        label: 'Email',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    www: {
        label: 'WWW',
        sizeMax: 255,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    legalStatus: {
        label: 'Legal status',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    entityType: {
        label: 'Entity type',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 1
    },
    dataSource: {
        label: 'Źródło danych',
        sizeMax: 255,
        get validations() { return [maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 1
    },
    additiveManufacturing3dPrinting: {
        label: 'Additive Manufacturing & 3D-Printing',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 2
    },
    microNanoTechnology: {
        label: 'Micro-&Nanotechnology-related Processes & Materials',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 2
    },
    industry40: {
        label: 'Industry 4.0',
        validations: isBlank,
        get validationError() { return this.label + ' is required' },
        step: 2
    },
    competencesDesc: {
        label: 'Competences description',
        sizeMax: 5555,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 2
    },
    infrastructureDescription: {
        label: 'Opis współpracy',
        sizeMax: 5556,
        get validations() { return [isBlank, maxSize(this.sizeMax)] },
        get validationError() {
            if (isBlank(this.value))
                return this.label + ' is required'
            return this.label + ' max size is ' + this.sizeMax
        },
        step: 3
    },
}