import React, { useState } from 'react'
import { Search } from 'semantic-ui-react'
import _ from 'lodash';
//import { useSearchJednostkiPwrQuery } from '../../redux/JednostkiPwrApi'
import { useGetPracownicyPwrSearchQuery } from '../../redux/PracownicyPwrApi'

const PracownicySearch = (props) => {
    const { onPracownikChange, defaultValue, placeholder, onSearchClear } = props
    const [searchText, setSearchText] = useState(defaultValue)
    const searchRequest = { searchText }
    const { data, error, isLoading } = useGetPracownicyPwrSearchQuery({ searchRequest, size:10 })
    //console.log('PracownicySearch data', data)
    const foundedList = _.get(data, 'content', []) //data && data.content ? data.content : []
    const resultList = foundedList.map(pracownik => { 
        return { id: pracownik.id, name: pracownik.name, email: pracownik.email, title: pracownik.jednostkaNad } 
    })
    const onSelectionChange = (event, data) => {
        //console.log('onSelectionChange data', data)
        const pracownikSelected = data.result
        setSearchText(pracownikSelected.name)

        if (typeof onPracownikChange === 'function') {
            onPracownikChange(pracownikSelected)
        } else {
            if (onPracownikChange) console.error("PracownicySearch's prop 'onPracownikChange' is not a function")
            else console.error("PracownicySearch has no prop 'onPracownikChange'")
        }
    }
    const onSearchChange = (event, data) => {
        const text = data.value;
        setSearchText(text)
        if (!text) onSearchClear()
    }

    return (
        error ?
        <span>Błąd</span>
        :        
        <Search
            loading={isLoading}
            onResultSelect={onSelectionChange}
                onSearchChange={onSearchChange}
                results={resultList}
            value={searchText}
                placeholder={placeholder || '...'}
            autoFocus
            resultRenderer={resultRenderer}

            //showNoResults={!readOnly}
            //readOnly={readOnly}
            //icon={readOnly ? null : 'search'}
            //{...restProps}
        />
    )
}

const resultRenderer = ({ id, name, title }) => (
    <div key={id} className='content'>
        {name && <div className='title'>{name}</div>}
        {title && <div className='description'>{title}</div>}
    </div>
)

export default PracownicySearch