import packageJson from './../package.json';
import preval from 'preval.macro';
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { withRouter } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './App.css'
import 'font-awesome/css/font-awesome.min.css'
import AppRouter from './AppRouter'
import { LoginModal, ForgotPasswordButton, RegisterNewAccountButton } from './components/user/LoginPage';
import { UserContext, defaultUser } from './user-context';
import { userService } from './components/user/user.service.js';
import ReactGA from 'react-ga'

function initializeReactGA() {
    ReactGA.initialize('UA-140243611-1', {
        testMode: window.location.hostname !== 'synpro.e-science.pl',
    });
    ReactGA.pageview('/homepage');
}
class App extends React.Component {
    constructor(props) {
        super(props);

        initializeReactGA()


        this.setLoggedUser = newUser => {
            console.log('App.setLoggedUser ', newUser);
            if (!newUser) {
                userService.logout();
                this.state.userContext.logout();
            }

            let userContext =
            {
                ...Object.assign({ ...defaultUser }, { ...userService.loggedUser() }),
                setLoggedUser: this.setLoggedUser,
                handleShowLoginForm: this.handleShowLoginForm,
                handleCloseLoginForm: this.handleCloseLoginForm,
            }

            if (newUser && this.state.showLoginForm) {
                //this.handleCloseLoginForm();
                this.setState({ showLoginForm: false });
            }

            this.setState(state => ({
                user: newUser,
                userContext
            }));
        };

        this.userContext = {
            ...defaultUser,
            setLoggedUser: this.setLoggedUser,
            handleShowLoginForm: this.handleShowLoginForm,
            handleCloseLoginForm: this.handleCloseLoginForm,
        };

        this.state = {
            userContext: this.userContext,
            user: null,
            showLoginForm: false,
        }


    }

    componentDidMount() {
        if (this.user_token){
            const handleFetchError = error => console.log('loginByExternalToken error', error)
            userService.loginByExternalToken(this.user_token, this.setLoggedUser, handleFetchError)
        }
    }

    handleShowLoginForm = () => this.setState({ showLoginForm: true })
    handleCloseLoginForm = () => this.setState({ showLoginForm: false })
    handleNewAccount = (e) => {
        this.props.history.push('/register_new_account')
        this.setState({ showLoginForm: false })
    }

    handleForgotPasswordClick = () => {
        this.props.history.push('/forgot-password')
        this.setState({ showLoginForm: false })
    }

    render() {
        const parsedUrl = new URL(window.location.href)
        //console.log('App.render ', this.state.userContext, this.state.showLoginForm);
        return (
            <Provider store={store}>
            <UserContext.Provider value={this.state.userContext}>
                <div className="App" data_build_version={packageJson.version} data_build_time={preval`module.exports = new Date().toISOString();`}>
                        <AppRouter setLoggedUser={this.setLoggedUser} />
                    {/* <Switch>
                        {
                            tokenInQuery && <Redirect to="/infrastructures" />
                        }
                        <Route path="/" exact component={HomePage}></Route>
                        <Route path="/projects">
                            <PrivateRoute setLoggedUser={this.setLoggedUser} >
                                <MainContent><ProjectList /></MainContent>
                            </PrivateRoute>
                        </Route>
                        <Route path="/organizations">
                            <PrivateRoute setLoggedUser={this.setLoggedUser} >
                            <MainContent><OrganizationList /></MainContent>
                            </PrivateRoute>
                        </Route>
                        <Route path="/map" render={(props) => <MainContent><MapComponent {...props} /></MainContent>}></Route>
                        <Route path="/register_new_account" render={props => <MainContent><RegisterNewAccount {...props} /></MainContent>}></Route>
                        <Route path="/forgot-password" render={props => <MainContent><ForgotPasswordForm {...props} /></MainContent>}></Route>
                        <Route path="/reset-password" render={props => <MainContent><ResetPasswordForm {...props} /></MainContent>}></Route>
                        <Route path="/change_password" render={props => <MainContent><ChangePasswordForm {...props} /></MainContent>}></Route>
                        <Route path="/matchmaking_results" render={(props) => <MainContent><MatchmakingResults {...props} /></MainContent>}></Route>
                        <Route path="/matchmaking_data_source" render={(props) => <MainContent><MatchmakingDataSource {...props} /></MainContent>}></Route>
                        <Route path="/users" render={(props) => <MainContent><UserList {...props} /></MainContent>}></Route>
                        <Route path="/404" component={NotFound} />
                        <Route path="/analysis" render={(props) => <MainContent><Analysis {...props} /></MainContent>}></Route>
                        <Route path="/infrastructures">
                            <PrivateRoute setLoggedUser={this.setLoggedUser} >
                            <MainContent><Infrastructure /></MainContent>
                            </PrivateRoute>
                        </Route>
                            <Route path="/pracownicy">
                                <PrivateRoute setLoggedUser={this.setLoggedUser} >
                                    <PracownicyRoute />
                                </PrivateRoute>
                            </Route>
                            <Route path="/jednostki">
                                <PrivateRoute setLoggedUser={this.setLoggedUser} >
                                    <JednostkiRoute />
                                </PrivateRoute>
                            </Route>
                        <Route path="/infrastructure-requests"><MainContent><InfrastructureRequestList /></MainContent></Route>
                        <Route path="/pwr-login"><MainContent><PwrLogin setLoggedUser={this.setLoggedUser} /></MainContent></Route>
                        <Route path="/success-login"><MainContent><SuccessLogin setLoggedUser={this.setLoggedUser} /></MainContent></Route>
                        <Route path="/counter-consumer"><CounterConsumer /></Route>
                        
                        <Redirect to="/404" />
                    </Switch> */}

                    <ToastContainer
                        position={toast.POSITION.TOP_RIGHT}
                        closeOnClick={false}
                        autoClose={6000}
                        hideProgressBar={true}
                    />

                    <LoginModal setLoggedUser={this.setLoggedUser} open={this.state.showLoginForm} onClose={this.handleCloseLoginForm}>
                        <ForgotPasswordButton onClick={this.handleForgotPasswordClick} />
                        <RegisterNewAccountButton onClick={this.handleNewAccount} />
                    </LoginModal>
                </div>
            </UserContext.Provider>
            </Provider>
        )
    }
}

export default withRouter(App);