import React, { useEffect, useState } from 'react'
import { Route, Link, Switch } from 'react-router-dom'
import client from '../../lib/client.js'
import OrganizationCreateForm from './OrganizationCreateForm.js'
import { Button, Table, Icon, Confirm, Modal } from 'semantic-ui-react'
import './Organization.css'
import '../project/ProjectList.css'
import { toast } from 'react-toastify';
import { userService } from '../user/user.service.js'
import * as utils from '../../lib/utils.js'
import { modules, pathToApi} from '../../lib/connectionConst'
import ReactGA from 'react-ga'
import OrganizationApi from './OrganizationApi';
import { DataList } from '../DataList';
import { DataTable, DataTableHeaderCell } from '../DataTable';
import { SearchBar } from '../SearchBar';

const OrganizationList = props => {
    const [searchText, setSearchText] = useState('')
    const [showNewOrganizationInfo, setShowNewOrganizationInfo] = useState(false)
    useEffect(() => { ReactGA.pageview('/organizations') }, [])

    const getDefaultUrl = () => {
        if (searchText !== '') {
            return pathToApi(modules.ORGANIZATION)
                + `/search/byNameOrContact?searchText=${searchText}&page=0`
        }
        return pathToApi(modules.ORGANIZATION)
    }

    return (
        <div>
            <DataList apiDataFetchUrl={getDefaultUrl()} apiEntityName='organizations' defaultSortBy='nameEnglish,asc'>
                {({ loading, data, sortMetaData, page, ...rest }) =>
                    <>
                        <SearchBar onSearch={searchText => setSearchText(searchText)}>
                            {/* <Button content='Register your organization' icon='add' labelPosition='left' floated='right'
                                primary as={Link} to="/organizations/register" /> */}
                            <Modal
                                onClose={() => setShowNewOrganizationInfo(false)}
                                onOpen={() => setShowNewOrganizationInfo(true)}
                                open={showNewOrganizationInfo}
                                trigger={<Button content='Dodawanie organizacji' icon='add' labelPosition='left' floated='right' />}
                            >
                                <Modal.Header>Nie można tutaj dodać organizacji</Modal.Header>
                                <Modal.Content>Nowe organizacje są dodawane w systemie TETA. Należy złożyć wniosek.</Modal.Content>
                            </Modal>
                        </SearchBar>

                        <OrganizationTable loading={loading} sort={sortMetaData} organizations={data}
                            totalPages={page.totalPages} activePage={page.number} totalElements={page.totalElements}
                            {...rest}
                        />

                        <Routes onCreate={rest.onCreate} onUpdate={rest.onUpdate} />
                    </>}
            </DataList>
        </div>
    )
}

const Routes = ({ onCreate, onUpdate }) => (
    <Switch>
        <Route path="/organizations/register" exact render={props =>
            <OrganizationCreateForm {...props} authRequired onSubmit={onCreate} />
        } />
        <Route path="/organizations/:organizationId/authrequired" render={props =>
            <OrganizationCreateForm authRequired {...props} onSubmit={onUpdate}
                title={<span><Icon name='edit' /> Organizacja</span>} />
        } />
        <Route path="/organizations/:organizationId" render={props =>
            <OrganizationCreateForm {...props} onSubmit={onUpdate}
                title={<span><Icon name='edit' /> Organizacja</span>} />
        } />
    </Switch>
)

class DeleteOrganizationButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            disabled: false,
            open: false,
            partnerProjects: []
        };
    }

    show = () => this.setState({ open: true })
    handleCancel = () => this.setState({ open: false })
    handleConfirm = (evt) => {
        this.handleDelete(this.props.organization, evt)
    }

    fetchPartnerProjects = () => {
        this.setState({ loading: true, disabled: true })
        let { organization } = this.props;

        client({ path: organization._links.projectPartners.href })
            .then(response => {
                let projects = response.entity._embedded.projectPartners.map(o => o._embedded.project.title + ' (' + o._embedded.project.acronym + ')');
                this.setState({ partnerProjects: projects })
            })
            .finally(() => this.setState({ open: true, loading: false, disabled: false }))
    }

    handleDelete = (organization, e) => {
        this.setState({
            loading: true,
            disabled: true,
            open: false
        });

        client({ method: 'DELETE', path: organization._links.self.href })
            .then(response => {
                this.props.onDelete(organization, <span>Organization deleted: <em>{organization.nameEnglish}</em></span>, e);
            }, error => {
                toast.error(<span>{error.status.text}</span>, { autoClose: false });
            }).finally(() => {
                this.setState({
                    loading: false,
                    disabled: false
                })
            })
    }

    render() {
        let { organization } = this.props;
        let confirmContent =
            <div className='content'>
                Are you sure to delete <em>{organization.nameEnglish}</em>?
                {this.state.partnerProjects.length > 0 &&
                    <div>
                        <div>It is a partner in the following projects:</div>
                        <ul>
                            {this.state.partnerProjects.map(o => <li>{o}</li>)}
                        </ul>
                    </div>
                }
            </div>;

        return (
            <>
                <Button icon='delete' compact title='Delete' loading={this.state.loading} disabled={this.state.disabled}
                    onClick={this.fetchPartnerProjects}
                />
                <Confirm open={this.state.open} onCancel={this.handleCancel} onConfirm={this.handleConfirm}
                    dimmer='inverted' header='Delete organization' content={confirmContent}
                />
            </>
        )
    }
}

const OrganizationTable = ({ organizations, loading, sort, ...rest }) => {
    const loggedUser = userService.loggedUser();

    return (
        <DataTable data={organizations} loading={loading} sortMetaData={sort} footerColSpan={loggedUser ? 6 : 5} {...rest}
            header={headerProps => (
                <Table.Header>
                    <Table.Row>
                        <DataTableHeaderCell field='nameEnglish' label='Nazwa' {...headerProps} />
                        <DataTableHeaderCell field='contactPerson' label='Osoba kontaktowa' {...headerProps} />
                        <DataTableHeaderCell field='entityType' label='Typ jednostki' {...headerProps} />
                        <DataTableHeaderCell field='country' label='Kraj' {...headerProps} />
                        <DataTableHeaderCell field='updateTimestamp' label='Zaktualizowano' className='columnUpdated' {...headerProps} />
                        {loggedUser && <Table.HeaderCell collapsing></Table.HeaderCell>}
                    </Table.Row>
                </Table.Header>
            )}
            body={({ data, onDelete }) => (
                <Table.Body>
                    {data.map(organization => {
                        const canEdit = userService.isAdmin() || userService.isAuthorizing()
                            || (loggedUser && loggedUser.id === organization.userOwnerId)
                            || (loggedUser && loggedUser.id === organization.userCreateId);

                        return (
                            <Table.Row key={organization._links.self.href}>
                                <Table.Cell className='preLine'>
                                    <Link to={`/organizations/${OrganizationApi.organizationId(organization)}`}
                                        title='Preview'>
                                        {organization.nameEnglish}
                                    </Link>
                                </Table.Cell>
                                <Table.Cell className='preLine'>
                                    {organization.contactPerson}
                                </Table.Cell>
                                <Table.Cell>
                                    {OrganizationApi.entityTypeByCode(organization.entityType)}
                                </Table.Cell>
                                <Table.Cell>
                                    {organization.country}
                                </Table.Cell>
                                <Table.Cell textAlign='center'>
                                    {utils.timestampCutSeconds(organization.updateTimestamp)}
                                </Table.Cell>
                                {loggedUser &&
                                    <Table.Cell>
                                        <Button.Group basic>
                                            {canEdit && <Button icon='edit' title='Edit' as={Link} to={`/organizations/${OrganizationApi.organizationId(organization)}`} />}
                                            {canEdit && <DeleteOrganizationButton organization={organization} onDelete={onDelete} />}
                                        </Button.Group>
                                    </Table.Cell>
                                }
                            </Table.Row>
                        )
                    }
                    )}
                </Table.Body>
            )}
        />
    )
}

export default OrganizationList
