import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    page: 0,
    totalCount: -1,
}

export const infrastrukturaPagingSlice = createSlice({
    name: 'infrastrukturaPaging',
    initialState,
    reducers: {
        increment: (state) => {
            state.page += 1
        },
        decrement: (state) => {
            state.page -= 1
        },
        setPage: (state, action) => {
            state.page = action.payload
        },
        setTotalCount: (state, action) => {
            state.totalCount = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, setPage, setTotalCount } = infrastrukturaPagingSlice.actions

export default infrastrukturaPagingSlice.reducer