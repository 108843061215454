import { createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const pracownicyPwrPageApi = createApi({
    reducerPath: 'pracownicyPwrPageApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/rest/' }),
    endpoints: (builder) => ({
        getPracownicyPwrPageAll: builder.query({
            query: () => `pracownicy_pwr_page.json`,
        }),
    }),
})

export default pracownicyPwrPageApi
export const { useGetPracownicyPwrPageAllQuery } = pracownicyPwrPageApi
