import React, { useState } from 'react'
import { Form, Step, Grid, Checkbox, Card, Image, Button, Label } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import { infrastructureFields as fields } from './InfrastructureFields';
import CountryRegionGroup from '../inputs/CountryRegionGroup'
import FilePreviewWall from './files/FilePreviewWall';
import { ProjectPartersSearch as OrganizationSearch } from '../project/ProjectPartners';
import Dropdown from '../inputs/Dropdown.js'
import DropdownTree from '../inputs/DropdownTree.js'
import * as utils from '../../lib/utils.js';
import { SelectGeolocationFromMap } from '../map/Geolocation';
import { isImageContentType } from './files/file-tools';
import InfoIconTip from '../inputs/InfoIconTip';
import { isFieldValid } from '../../lib/validation.js'
import InfrastructureApi from './InfrastructureApi'
import JednostkaSearch from '../jednostki/JednostkaSearch'
import { userService } from '../user/user.service.js';
import PracownikSearch from '../pracownicy/PracownicySearch.js'

export const Steps = ({ currentStep, onStepClick }) => (
    <Step.Group attached='top' size='tiny' widths={3}>
        <Step active={currentStep === 1}
            data-stepnumber={1} title='Informacje ogólne' link //General information
            onClick={onStepClick}
        />

        <Step active={currentStep === 2}
            data-stepnumber={2} title='Karta urządzenia' link // Specifications/Technical Details/Pictures
            onClick={onStepClick}
        />
        <Step active={currentStep === 3}
            data-stepnumber={3} title='Specyfikacje/Szczegóły techniczne/Zdjęcia' link // Specifications/Technical Details/Pictures
            onClick={onStepClick}
        />
    </Step.Group>
)

export const StepGeneralInformation = ({ infrastructure, readOnly, onInfrastructureChange, invalidFields }) => {
    const { lat, lon, displayName } = infrastructure.geoSearchData || {};
    const firstImageFromFilelist = infrastructure.fileList.find(({ type }) => isImageContentType(type));

    return (
        <div className='stepContent stepContentInfrastructure'>
            <span className='generalInformationSingleUpload'>
                <FilePreviewWall
                    fileList={[firstImageFromFilelist || []].flat()}
                    filesToDelete={infrastructure.filesToDelete}
                    onChange={onInfrastructureChange}
                    readOnly={readOnly}
                    multiple={false}
                    listTypeToggling={false}
                    maxFiles={1}
                    defaultListType='picture-card'
                    placeholder={<span>Select or drop main picture of your infrastructure</span>}
                    accept='image/*'
                />
            </span>

            <Form.TextArea id='form-input-name'
                label={fields.name.label}
                placeholder={fields.name.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='name'
                value={infrastructure.name || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                required={!readOnly}
                readOnly={readOnly}
                error={!isFieldValid(infrastructure.name, fields.name.validations, infrastructure)}
            />
            <Form.TextArea id='form-input-name-eng'
                label={fields.nameEng.label}
                placeholder={fields.nameEng.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='nameEng'
                value={infrastructure.nameEng || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                required={!readOnly}
                readOnly={readOnly}
                error={!isFieldValid(infrastructure.nameEng, fields.nameEng.validations, infrastructure)}
            />
            <Dropdown
                label={'Typ infrastruktury'}
                name='typInfrastruktury'
                value={infrastructure.typInfrastruktury}
                dataUrl='/data/infrastructure_typ_infrastruktury.json'
                onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                //required={!readOnly} upward
                readOnly={readOnly}
                //error={!isFieldValid(props.organization.legalStatus, fields.legalStatus.validations, props.organization)}
            //error={props.invalidFields.find(o => o.name === 'legalStatus') !== undefined}
            />

            <Form.TextArea id='form-input-description'
                label={
                    <label>
                        <span>
                            {fields.description.label}
                            <InfoIconTip content='Proszę podać ogólne informacje na temat sprzętu (producent i model), technologii, wydajności i głównych parametrów wyjściowych.' visible={!readOnly} />
                        </span>
                        <CharLimit
                            visible={!readOnly}
                            sizeCurrent={utils.formFieldLength(infrastructure.description)}
                            sizeMax={fields.description.sizeMax}
                        />
                    </label>
                }
                placeholder={fields.description.label}
                autoFocus control={TextareaAutosize} rows={1}
                value={infrastructure.description || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                name='description'
                readOnly={readOnly}
                required={!readOnly}
                error={!isFieldValid(infrastructure.description, fields.description.validations, infrastructure)}
                //error={invalidFields.find(o => o.name === 'description') !== undefined}
            />
            <Form.TextArea id='form-input-description-en'
                label={
                    <label>
                        <span>
                            {fields.descriptionEng.label}
                            <InfoIconTip content='Please provide general information about the equipment (manufacturer and model), technology, capacity and main outputs.' visible={!readOnly} />
                        </span>
                        <CharLimit
                            visible={!readOnly}
                            sizeCurrent={utils.formFieldLength(infrastructure.descriptionEng)}
                            sizeMax={fields.descriptionEng.sizeMax}
                        />
                    </label>
                }
                placeholder={fields.descriptionEng.label}
                autoFocus control={TextareaAutosize} rows={1}
                value={infrastructure.descriptionEng || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                name='descriptionEng'
                readOnly={readOnly}
                //required={!readOnly}
                error={!isFieldValid(infrastructure.descriptionEng, fields.descriptionEng.validations, infrastructure)}
            />
            <Form.Field
                error={invalidFields.find(o => o.name === 'organizationId') !== undefined}
            >
                <label>{fields.organizationId.label}</label>
                <OrganizationSearch
                    placeholder='Search for organization...'
                    defaultValue={infrastructure.organizationName || ''}
                    insertValueOnSelect={true}
                    autoFocus={false}
                    readOnly={readOnly}
                    onProjectPartnerAdd={(e, data) => onInfrastructureChange({ organizationId: data.id, organizationName: data.title })}
                    onSearchClear={() => onInfrastructureChange({ organizationId: null, organizationName: '' })}
                />
            </Form.Field>
            <fieldset className='ui segment'>
                <legend>Lokalizacja infrastruktury</legend>

                <Form.Input id='form-input-jednostka_pwr' label={'Jednostka PWr'}
                    placeholder={'Jednostka PWr'}
                    //required={!readOnly}
                    name="tetaJednostkaUzytkowania" value={infrastructure.tetaJednostkaUzytkowania || ''}
                    onChange={e => handleInputChange(e, onInfrastructureChange)}
                    readOnly={readOnly}
                    error={invalidFields.find(o => o.name === 'tetaJednostkaUzytkowania') !== undefined}
                />
                {/* <JednostkaSearch
                    placeholder='Search for organization...'
                    defaultValue={infrastructure.organizationName || ''}
                    insertValueOnSelect={true}
                    autoFocus={false}
                    readOnly={readOnly}
                    onProjectPartnerAdd={(e, data) => onInfrastructureChange({ organizationId: data.id, organizationName: data.title })}
                    onSearchClear={() => onInfrastructureChange({ organizationId: null, organizationName: '' })}
                /> */}
                <Form.Input id='form-input-lokalizacja_pwr' label={'Lokalizacja PWr (budynek, sala)'}
                    placeholder={'lokalizacja PWr'} 
                    //required={!readOnly}
                    name="lokalizacjaPwr" value={infrastructure.lokalizacjaPwr || ''}
                    onChange={e => handleInputChange(e, onInfrastructureChange)}
                    readOnly={readOnly}
                    error={invalidFields.find(o => o.name === 'lokalizacjaPwr') !== undefined}
                />

                {/* <Form.Input id='form-input-address' label={fields.address.label}
                    placeholder={fields.address.label}
                    name="address" value={infrastructure.address || ''}
                    onChange={e => handleInputChange(e, onInfrastructureChange)}
                    readOnly={readOnly}
                    error={invalidFields.find(o => o.name === 'address') !== undefined}
                /> */}

                {/* <Form.Group>
                    <Form.Input id='form-input-zip_code' label={fields.zipCode.label}
                        placeholder={fields.zipCode.label}
                        name="zipCode" value={infrastructure.zipCode || ''}
                        onChange={e => handleInputChange(e, onInfrastructureChange)}
                        width={5}
                        readOnly={readOnly}
                        error={invalidFields.find(o => o.name === 'zipCode') !== undefined}
                    />
                    <Form.Input id='form-input-city' label={fields.city.label} placeholder={fields.city.label}
                        name="city" value={infrastructure.city || ''}
                        onChange={e => handleInputChange(e, onInfrastructureChange)}
                        width={11}
                        readOnly={readOnly}
                        error={invalidFields.find(o => o.name === 'city') !== undefined}
                    />
                </Form.Group> */}

                {/* <CountryRegionGroup
                    entity={infrastructure}
                    onChange={onInfrastructureChange}
                    readOnly={readOnly}
                    countryLabel={fields.country.label}
                    regionLabel={fields.region.label}
                    countryValidations={fields.country.validations}
                    countryRequired
                    invalidFields={invalidFields}
                /> */}

                {/* <Form.Group className='geolocation'>
                    <Form.Input id='form-input-lat' label='Szerokość geograficzna' placeholder='Szerokość geograficzna' name='lat' type='number'
                        value={lat || ''} width={8} readOnly={readOnly}
                        onChange={({ target }) => {
                            const { value } = target;
                            const geoSearchData = { ...infrastructure.geoSearchData, lat: parseFloat(value) }
                            onInfrastructureChange({ geoSearchData })
                        }}
                    />
                    <Form.Input id='form-input-lng' label='Długość geograficzna' placeholder='Długość geograficzna' name='lng' type='number'
                        value={lon || ''} width={8} readOnly={readOnly}
                        onChange={({ target }) => {
                            const { value } = target;
                            const geoSearchData = { ...infrastructure.geoSearchData, lon: parseFloat(value) }
                            onInfrastructureChange({ geoSearchData })
                        }}
                    />
                    <SelectGeolocationFromMap
                        lat={lat} lng={lon}
                        displayName={displayName}
                        readOnly={readOnly}
                        onPositionSelected={({ lat, lng, displayName }) => {
                            const geoSearchData = { ...infrastructure.geoSearchData, lat, lon: lng, displayName }
                            onInfrastructureChange({ geoSearchData })
                        }}
                    />
                </Form.Group> */}
            </fieldset>

            <Form.TextArea id='form-input-researchServices'
                label={
                    <label>
                        <span>
                            {fields.researchServices.label}
                            {/* <InfoIconTip content='Please describe the specific services that you are able to provide based on the described infrastructure, and also availability of expert staff to operate the infrastructure.' visible={!readOnly} /> */}
                        </span>
                        <CharLimit
                            visible={!readOnly}
                            sizeCurrent={utils.formFieldLength(infrastructure.researchServices)}
                            sizeMax={fields.researchServices.sizeMax}
                        />
                    </label>
                }
                placeholder={fields.researchServices.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='researchServices'
                value={infrastructure.researchServices || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                //required
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'researchServices') !== undefined}
            />
            <Dropdown
                upward
                onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                value={utils.multiselectStringToArray(infrastructure.possibilitiesToRent)}
                label={fields.possibilitiesToRent.label}
                name='possibilitiesToRent'
                dataUrl='/data/infrastructure_possibilities_to_rent.json'
                readOnly={readOnly}
                multiple selection
                error={invalidFields.find(o => o.name === 'possibilitiesToRent') !== undefined}
            />
            <Form.TextArea id='form-input-website'
                label={fields.website.label}
                placeholder={fields.website.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='website'
                value={infrastructure.website || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'website') !== undefined}
            />
            <Form.TextArea id='form-input-openKeywords'
                label={fields.openKeywords.label}
                placeholder={fields.openKeywords.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='openKeywords'
                value={infrastructure.openKeywords || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'openKeywords') !== undefined}
            />
            <Form.TextArea id='form-input-possibleApplication'
                label={
                    <label>
                        <span>
                            {fields.possibleApplication.label}
                            <InfoIconTip content='Proszę opisać cel wykorzystania i możliwe zastosowania w różnych dziedzinach badań lub sektorach przemysłu, wraz z konkretnymi wynikami.' visible={!readOnly} />
                            {/* <InfoIconTip content='Please describe the purpose of use and possible application in different fields of research or sectors of industry, along with specific outputs.' visible={!readOnly} /> */}
                        </span>
                        <CharLimit
                            visible={!readOnly}
                            sizeCurrent={utils.formFieldLength(infrastructure.possibleApplication)}
                            sizeMax={fields.possibleApplication.sizeMax}
                        />
                    </label>
                }
                placeholder={fields.possibleApplication.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='possibleApplication'
                value={infrastructure.possibleApplication || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'possibleApplication') !== undefined}
            />

            {/* <div className='stepInfrastructure'>
                <DropdownTree
                    name='infrastructureAdditiveManufacturing'
                    label='Infrastructure Type Additive Manufacturing'
                    onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                    dataUrl='/data/organization_infrastructure_additive_manufacturing.json'
                    value={utils.multiselectStringToArray(infrastructure.infrastructureAdditiveManufacturing)}
                    readOnly={readOnly}
                />

                <DropdownTree
                    name='infrastructureIndustry40'
                    label='Infrastructure Type Industry 4.0'
                    onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                    dataUrl='/data/organization_infrastructure_industry_40.json'
                    value={utils.multiselectStringToArray(infrastructure.infrastructureIndustry40)}
                    readOnly={readOnly}
                />

                <DropdownTree
                    name='infrastructureMicroNanoManufacturing'
                    label='Infrastructure Type Micro/Nano Manufacturing'
                    onChange={(e, dropdownData) => handleDropdownChange(dropdownData, onInfrastructureChange)}
                    dataUrl='/api/dictionary/infrastructure_micro_nano_manufacturing/dropdown_tree'
                    value={utils.multiselectStringToArray(infrastructure.infrastructureMicroNanoManufacturing)}
                    readOnly={readOnly}
                />
            </div> */}

            <Form.TextArea id='form-input-acquisitionYear'
                label={fields.tetaRokProdukcji.label}
                placeholder={fields.tetaRokProdukcji.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='tetaRokProdukcji'
                value={infrastructure.tetaRokProdukcji || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                //required
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'tetaRokProdukcji') !== undefined}
            />
            <Form.TextArea id='form-input-usagePrice'
                label={fields.usagePrice.label}
                placeholder=''
                autoFocus control={TextareaAutosize} rows={1}
                name='usagePrice'
                value={infrastructure.usagePrice || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                //required={!readOnly}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'usagePrice') !== undefined}
            />
            {/* <Form.TextArea id='form-input-OsobaEwidencja'
                label={"Osoba ewidencjonująca"}
                placeholder={"Osoba ewidencjonująca"}
                autoFocus control={TextareaAutosize} rows={1}
                name='tetaOsobaEwidencja'
                value={infrastructure.tetaOsobaEwidencja || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={true}
                error={invalidFields.find(o => o.name === 'tetaOsobaEwidencja') !== undefined}
            /> */}
            <OsobaEwidencja infrastructure={infrastructure} fields={fields} invalidFields={invalidFields}
                onInfrastructureChange={onInfrastructureChange} readOnly={readOnly} />
            <OsobaOdpowiedzialna infrastructure={infrastructure} fields={fields} invalidFields={invalidFields} 
                onInfrastructureChange={onInfrastructureChange} readOnly={readOnly} />
            <OsobaKierownik infrastructure={infrastructure} fields={fields} invalidFields={invalidFields}
                onInfrastructureChange={onInfrastructureChange} readOnly={readOnly} />
            
            {/* <OsobyPrzypisaneDoInfrastruktury infrastructure={infrastructure} /> */}
        </div>
    )
}

export const StepKartaUrzadzenia = ({ infrastructure, readOnly, onInfrastructureChange, invalidFields, pracownicyPowiazani }) => {
    const mozeAkceptowacAutor = InfrastructureApi.edytujeAutor(infrastructure);
    const mozeAkceptowacAparaturowiec = InfrastructureApi.edytujeAparaturowiec(infrastructure);
    const mozeAkceptowacKierownik = false;
    const mozeAkceptowacCib = false;
    return (
        <div className='stepContent stepContentInfrastructure'>

            <Form.TextArea id='form-input-producent'
                label='Nazwa wytwócy/producenta'
                placeholder={'Nazwa wytwócy /producenta'}
                autoFocus control={TextareaAutosize} rows={1}
                name='producent'
                value={infrastructure.producent || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                //required={!readOnly}
                readOnly={readOnly}
                //error={invalidFields.find(o => o.name === 'producent') !== undefined}
            />
            <Form.Input id='form-input-kraj_pochodzenia' label={'Kraj pochodzenia'}
                placeholder={'Kraj pochodzenia'}
                //required={!readOnly}
                name="krajPochodzenia" value={infrastructure.krajPochodzenia || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
                error={invalidFields.find(o => o.name === 'krajPochodzenia') !== undefined}
            />
            <Form.Input id='form-input-kwota_zakupu' label={'Cena jednostkowa / kwota zakupu'}
                placeholder={'Cena jednostkowa / kwota zakupu'}
                //required={!readOnly}
                name="kwotaZakupu" value={infrastructure.kwotaZakupu || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
                //error={invalidFields.find(o => o.name === 'kwotaZakupu') !== undefined}
            />
            <Form.Input id='form-input-numer_faktury' label={'Numer faktury lub rachunku'}
                placeholder={'Numer faktury lub rachunku'}
                //required={!readOnly}
                name="numerFaktury" value={infrastructure.numerFaktury || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
                //error={invalidFields.find(o => o.name === 'numerFaktury') !== undefined}
            />
            <Form.TextArea id='form-input-zrodlo_finansowania'
                label={'Nazwa źródła finansowania'}
                placeholder={'Nazwa źródła finansowania'}
                autoFocus control={TextareaAutosize} rows={1}
                name='zrodloFinansowania'
                value={infrastructure.zrodloFinansowania || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                //required={!readOnly}
                readOnly={readOnly}
                //error={invalidFields.find(o => o.name === 'zrodloFinansowania') !== undefined}
            />

            <Form.Input id='form-input-trwalosc_projektowa' label={'Trwałość projektowa'}
                placeholder={'Trwałość projektowa'}
                //required={!readOnly}
                name="trwaloscProjektowa" value={infrastructure.trwaloscProjektowa || ''}
                type='date'
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'trwaloscProjektowa') !== undefined}
            />
            <Form.Input id='form-input-ciezar' label={'Masa'}
                placeholder={'Masa'}
                //required={!readOnly}
                name="masa" value={infrastructure.masa || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'masa') !== undefined}
            />
            <Form.Input id='form-input-gabaryty' label={'Wymiary'}
                placeholder={'Gabaryty'}
                //required={!readOnly}
                name="wymiary" value={infrastructure.wymiary || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'wymiary') !== undefined}
            />
            <Form.Input id='form-input-data_uruchomienia' label={'Data uruchomienia'}
                placeholder={'Data uruchomienia'}
                //required={!readOnly}
                name="dataUruchomienia" value={infrastructure.dataUruchomienia || ''}
                type='date'
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'dataUruchomienia') !== undefined}
            />
            <Form.Input id='form-input-data_zakupu' label={'Data zakupu'}
                placeholder={'Data zakupu'}
                //required={!readOnly}
                name="tetaDataZakupu" value={infrastructure.tetaDataZakupu || ''}
                type='date'
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'tetaDataZakupu') !== undefined}
            />
            <Form.Input id='form-input-numer_fabryczny' label={'Numer fabryczny'}
                placeholder={'Numer fabryczny'}
                //required={!readOnly}
                name="numerFabryczny" value={infrastructure.numerFabryczny || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'numerFabryczny') !== undefined}
            />
            <Form.Input id='form-input-numer_inwentarzowy' label={'Numer inwentarzowy'}
                placeholder={'Numer inwentarzowy'}
                //required={!readOnly}
                name="numerInwentarzowy" value={infrastructure.numerInwentarzowy || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'numerInwentarzowy') !== undefined}
            />
            <hr />
            <Autorzy pracownicyPowiazani={pracownicyPowiazani} />
            <Kierownicy pracownicyPowiazani={pracownicyPowiazani} />
            <hr />
            <Form.Field>
                <label>Zakończ edycję i przekaż do akceptacji</label>
                <Checkbox toggle label='po zapisie nastąpi przekazanie do akceptacji' name='akceptacjaAutora'
                    checked={infrastructure.akceptacjaAutora == 1 || false}
                    disabled={readOnly || !(mozeAkceptowacAutor || userService.isAdmin())} className='authorized'
                    onChange={(event, data) => onInfrastructureChange({ akceptacjaAutora: data.checked ? 1 : 0 })} />
            </Form.Field>
            <Form.Field>
                <label>Użycie komercyjne</label>
                <Checkbox toggle label='możliwe do użycia komercyjnego' name='mozliwoscUzyciaKomercyjnego'
                    checked={infrastructure.mozliwoscUzyciaKomercyjnego == 1 || false}
                    disabled={readOnly || !(mozeAkceptowacKierownik || userService.isAdmin())} className='authorized'
                    onChange={(event, data) => onInfrastructureChange({ mozliwoscUzyciaKomercyjnego: data.checked ? 1 : 0 })} />
            </Form.Field>
            <Form.Field>
                <label>Akceptacja opisu (kierownik jednostki)</label>
                <Checkbox toggle label='akceptacja opisu' name='akceptacjaOpisu'
                    checked={infrastructure.akceptacjaOpisu == 1 || false}
                    disabled={readOnly || !(mozeAkceptowacKierownik || userService.isAdmin())} className='authorized'
                    onChange={(event, data) => onInfrastructureChange({ akceptacjaOpisu: data.checked ? 1 : 0 })} />
            </Form.Field>
            <Form.Field>
                <label>Weryfikacja formalna (aparaturowiec)</label>
                <Checkbox toggle label='akceptacja opisu' name='weryfikacjaAparaturowca'
                    checked={infrastructure.weryfikacjaAparaturowca == 1 || false}
                    disabled={readOnly || !(mozeAkceptowacAparaturowiec || userService.isAdmin())} className='authorized'
                    onChange={(event, data) => onInfrastructureChange({ weryfikacjaAparaturowca: data.checked ? 1 : 0 })} />
            </Form.Field>
            <Form.Field>
                <label>Akceptacja treści (koordynator CIB)</label>
                <Checkbox toggle label='jakość treści zaakceptowana' name='akceptacjaTresci'
                    checked={infrastructure.akceptacjaTresci == 1 || false}
                    disabled={readOnly || !(mozeAkceptowacCib || userService.isAdmin())} className='authorized'
                    onChange={(event, data) => onInfrastructureChange({ akceptacjaTresci: data.checked ? 1 : 0 })} />
            </Form.Field>
        </div>
    )
}

export const StepSpecifications = ({ infrastructure, readOnly, onInfrastructureChange, invalidFields }) => (
    <div className='stepContent' >
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <FilePreviewWall
                        fileList={infrastructure.fileList}
                        filesToDelete={infrastructure.filesToDelete}
                        onChange={onInfrastructureChange}
                        readOnly={readOnly}
                    />
                </Grid.Column>
                <Grid.Column width={8}>
                    <Form.TextArea id='form-input-image-desc-copyright'
                        label={fields.imageDescriptionCopyright.label}
                        placeholder={fields.imageDescriptionCopyright.label}
                        autoFocus control={TextareaAutosize} rows={1}
                        name='imageDescriptionCopyright'
                        value={infrastructure.imageDescriptionCopyright || ''}
                        onChange={e => handleInputChange(e, onInfrastructureChange)}
                        readOnly={readOnly}
                        error={invalidFields.find(o => o.name === 'imageDescriptionCopyright') !== undefined}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
)

const Autorzy = ({ pracownicyPowiazani }) => {
    //console.log('Autorzy', pracownicyPowiazani)
    if (!pracownicyPowiazani || !pracownicyPowiazani.autorzy) return null
    return(
        <Card.Group>
            {pracownicyPowiazani.autorzy.map(pracownik =>
                <Card>
                    <Card.Meta>Autor</Card.Meta>
                    <Card.Content>
                        <Card.Header>{pracownik.name} </Card.Header>
                        <Card.Description>
                            <strong>{pracownik.department}</strong>
                        </Card.Description>
                    </Card.Content>
                </Card>
            )}
        </Card.Group>
    );
}

const Kierownicy = ({ pracownicyPowiazani }) => {
    console.log('Autorzy', pracownicyPowiazani)
    if (!pracownicyPowiazani || !pracownicyPowiazani.kierownicy) return null
    return (
        <Card.Group>
            {pracownicyPowiazani.kierownicy.map(pracownik => 
                <Card>
                    <Card.Meta>Kierownik</Card.Meta>
                    <Card.Content>
                        <Card.Header>{pracownik.name} </Card.Header>
                        <Card.Description>
                            <strong>{pracownik.role}</strong>
                        </Card.Description>
                    </Card.Content>
                </Card>
            )}
        </Card.Group>
    );
}

const OsobaOdpowiedzialna = ({ infrastructure, fields, invalidFields, readOnly, onInfrastructureChange }) => {
    const osoba = infrastructure.pracownikToInfrastructures && 
        infrastructure.pracownikToInfrastructures.find(osoba => osoba.role === 'autor')

    const [edycjaOsoby, setEdycjaOsoby] = useState(osoba === null)

    const handleChangeOsobaUzytkownik = pracownik => {
        console.log('handleChangePracownik', pracownik)
        onInfrastructureChange({'tetaOsobaUzytkownik': pracownik.name + ' id[' + pracownik.id + ']'});
        //infrastructure.tetaOsobaUzytkownik = pracownik.name + ' id[' + pracownik.id + ']';
        //onInfrastructureSearchFieldsChange({ 'pracownik': pracownik.email })
    }

    if (edycjaOsoby) {
        return (
            <Form.Field>
                <label>Wskaż nową osobę odpowiedzialną</label>
                <PracownikSearch
                    placeholder='wybierz pracownika...'
                    defaultValue={''}
                    //insertValueOnSelect={true}
                    autoFocus={false}
                    onPracownikChange={handleChangeOsobaUzytkownik}
                    //readOnly={readOnly}
                    onSearchClear={() => { }}
                />
            </Form.Field>
        )
    }

    return(
        <Form.Field>
            <label>Osoba odpowiedzialna</label>
            <OsobaPowiazanaCard osoba={osoba} />
            {/* <Form.TextArea id='form-input-responsiblePerson'
                label={fields.tetaOsobaUzytkownik.label}
                placeholder={fields.tetaOsobaUzytkownik.label}
                autoFocus control={TextareaAutosize} rows={1}
                name='tetaOsobaUzytkownik'
                value={infrastructure.tetaOsobaUzytkownik || ''}
                onChange={e => handleInputChange(e, onInfrastructureChange)}
                required={!readOnly}
                readOnly={true}
                error={invalidFields.find(o => o.name === 'tetaOsobaUzytkownik') !== undefined}
            /> */}
            <Label onClick={() => setEdycjaOsoby(true)}>Zmień osobę {infrastructure.tetaOsobaUzytkownik || ''}</Label>
        </Form.Field>
    )
}

const OsobaKierownik = ({ infrastructure, fields, invalidFields, readOnly, onInfrastructureChange }) => {
    const osoba = infrastructure.pracownikToInfrastructures &&
        infrastructure.pracownikToInfrastructures.find(osoba => osoba.role === 'kierownik')

    const [edycjaOsoby, setEdycjaOsoby] = useState(osoba === null)

    const handleChangeOsoba = pracownik => {
        onInfrastructureChange({ 'osobaKierownik': pracownik.name + ' id[' + pracownik.id + ']' });
    }

    if (edycjaOsoby) {
        return (
            <Form.Field>
                <label>Wskaż kierownika </label>
                <PracownikSearch
                    placeholder='wybierz pracownika...'
                    defaultValue={''}
                    //insertValueOnSelect={true}
                    autoFocus={false}
                    onPracownikChange={handleChangeOsoba}
                    //readOnly={readOnly}
                    onSearchClear={() => { }}
                />
            </Form.Field>
        )
    }

    return (
        <Form.Field>
            <label>Kierownik</label>
            <OsobaPowiazanaCard osoba={osoba} />
            <Label onClick={() => setEdycjaOsoby(true)}>Zmień kierownika</Label>
        </Form.Field>
    )
}

const OsobaEwidencja = ({ infrastructure, fields, invalidFields, readOnly, onInfrastructureChange }) => {
    const osoba = infrastructure.pracownikToInfrastructures &&
        infrastructure.pracownikToInfrastructures.find(osoba => osoba.role === 'aparaturowiec')

    const [edycjaOsoby, setEdycjaOsoby] = useState(osoba === null)

    const handleChangeOsoba = pracownik => {
        onInfrastructureChange({ 'tetaOsobaEwidencja': pracownik.name + ' id[' + pracownik.id + ']' });
    }

    if (edycjaOsoby) {
        return (
            <Form.Field>
                <label>Osoba ewidencja - wskaż pracownika</label>
                <PracownikSearch
                    placeholder='wybierz pracownika...'
                    defaultValue={''}
                    //insertValueOnSelect={true}
                    autoFocus={false}
                    onPracownikChange={handleChangeOsoba}
                    //readOnly={readOnly}
                    onSearchClear={() => { }}
                />
            </Form.Field>
        )
    }

    return (
        <Form.Field>
            <label>Osoba ewidencja</label>
            <OsobaPowiazanaCard osoba={osoba} />
            <Label onClick={() => setEdycjaOsoby(true)}>Zmień osobę ewidencji</Label>
        </Form.Field>
    )
}

const OsobyPrzypisaneDoInfrastruktury = ({ infrastructure}) => {
    if (!infrastructure.pracownikToInfrastructures) return null;
    return (
        <Form.Field key="pracownikToInfrastructures">
            <label>Osoby przypisane do infrastruktury</label>
            <Card.Group>
                {infrastructure.pracownikToInfrastructures.map(osoba => {
                    return(<OsobaPowiazanaCard osoba={osoba} />)
                    //console.log('osoba ', osoba)
                    // const prop = {}
                    // if (osoba.pracownikPwr.email === userService.loggedUser().email) prop.color = 'yellow'
                    // return (
                    //     <Card key={osoba.id} {...prop}>
                    //         <Card.Content>
                    //             <Card.Header>{osoba.role}</Card.Header>
                    //             <Card.Description>{osoba.pracownikPwr.name}</Card.Description>
                    //             <Card.Description>{osoba.pracownikPwr.email}</Card.Description>
                    //         </Card.Content>
                    //     </Card>
                    // )
                })}
            </Card.Group>
        </Form.Field>
    )
}

const OsobaPowiazanaCard = ({osoba}) => {
    console.log('OsobaPowiazanaCard osoba', osoba)
    if (!osoba) return null
    const prop = {}
    if (osoba.pracownikPwr.email === userService.loggedUser().email) prop.color = 'yellow'
    return (
        <Card key={osoba.id} {...prop}>
            <Card.Content>
                <Card.Header>{osoba.role}</Card.Header>
                <Card.Description>{osoba.pracownikPwr.name}</Card.Description>
                <Card.Description>{osoba.pracownikPwr.email}</Card.Description>
            </Card.Content>
        </Card>
    )
}

const handleInputChange = ({ target }, callback) => {
    const { value, name } = target;
    callback({ [name]: value });
}

const handleDropdownChange = (dropdownData, callback) => {
    const value = Array.isArray(dropdownData.selectedValues) ? utils.multiselectArrayToString(dropdownData.selectedValues) : dropdownData.selectedValues;
    const name = dropdownData.name;
    callback({ [name]: value });
}

const CharLimit = ({ sizeCurrent, sizeMax, visible = true }) =>
    visible && <span className='charLimit' title='Character limit'>{sizeCurrent}/{sizeMax}</span>
