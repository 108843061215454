import React, { useState } from 'react'
import { Search } from 'semantic-ui-react'
import _ from 'lodash';
import { useSearchJednostkiPwrQuery } from '../../redux/JednostkiPwrApi'

const JednostkaSearch = (props) => {
    const { onJednostkaChange, defaultValue, placeholder, onSearchClear } = props
    const [searchText, setSearchText] = useState(defaultValue)
    const { data, error, isLoading } = useSearchJednostkiPwrQuery(searchText)
    //console.log('JednostkaSearch data', data)
    const foundedList = _.get(data, 'content', []) //data && data.content ? data.content : []
    const onSelectionChange = (event, data) => {
        //console.log('onSelectionChange data', data)
        const jednostkaSelected = data.result
        setSearchText(jednostkaSelected.name)

        if (typeof onJednostkaChange === 'function') {
            onJednostkaChange(jednostkaSelected)
        } else {
            if (onJednostkaChange) console.error("JednostkaSearch's prop 'onJednostkaChange' is not a function")
            else console.error("JednostkaSearch has no prop 'onJednostkaChange'")
        }
    }
    const onSearchChange = (event, data) => {
        const text = data.value;
        setSearchText(text)
        if (!text && onSearchClear) onSearchClear()
    }

    return (
        error ?
        <span>Błąd</span>
        :        
        <Search
            loading={isLoading}
            onResultSelect={onSelectionChange}
                onSearchChange={onSearchChange}
                results={foundedList}
            value={searchText}
                placeholder={placeholder || '...'}
            autoFocus
            resultRenderer={resultRenderer}

            //showNoResults={!readOnly}
            //readOnly={readOnly}
            //icon={readOnly ? null : 'search'}
            //{...restProps}
        />
    )
}

const resultRenderer = ({ id, name, symbol, title }) => (
    <div key={id} className='content'>
        {name && <div className='title'>{name}</div>}
        {symbol && <div className='description'>{symbol}</div>}
    </div>
)

export default JednostkaSearch