import React from 'react'
import { Link } from 'react-router-dom'
import { Table, Image, Popup, Label } from 'semantic-ui-react'
import { Typography } from 'antd';
import '../project/ProjectList.css'
import { DataTable } from '../DataTable';
import * as utils from '../../lib/utils.js';
import InfrastructureApi from './InfrastructureApi';

const InfrastructureTable = ({ infrastructures, loading, sort, ...rest }) => (

    <DataTable data={infrastructures} loading={loading} sortMetaData={sort} footerColSpan={6} {...rest}
        header={({ sortMetaData, onSort }) => (
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell sorted={sortMetaData.field === 'name' ? sortMetaData.direction : null}
                        onClick={evt => onSort('name', evt)}>
                        Nazwa
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'akceptacjaAutora' ? sortMetaData.direction : null}
                        onClick={evt => onSort('akceptacjaAutora', evt)}>
                        Status
                    </Table.HeaderCell>
                    {/* onClick={evt => onSort('organizationNameEnglish', evt)} sorted={sortMetaData.field === 'organizationNameEnglish_todo' ? sortMetaData.direction : null} */}
                    <Table.HeaderCell sorted={sortMetaData.field === 'jednostkaPwrId' ? sortMetaData.direction : null}
                        onClick={evt => onSort('jednostkaPwrId', evt)}>
                        Organizacja
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'tetaOsobaUzytkownik' ? sortMetaData.direction : null}
                        onClick={evt => onSort('tetaOsobaUzytkownik', evt)}>
                        Odpowiedzialny
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'tetaOsobaEwidencja' ? sortMetaData.direction : null}
                        onClick={evt => onSort('tetaOsobaEwidencja', evt)}>
                        Ewidencjonujący
                    </Table.HeaderCell>
                    <Table.HeaderCell sorted={sortMetaData.field === 'updateTimestamp' ? sortMetaData.direction : null}
                        onClick={evt => onSort('updateTimestamp', evt)}>
                        Zaktualizowano
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Header>
        )}
        body={({ data, onDelete }) => (
            <Table.Body>
                {data.map(infrastructure => {
                    /* let loggedUser = userService.loggedUser();
                    const canEdit = userService.isAdmin() || userService.isAuthorizing()
                        || (loggedUser && loggedUser.id === infrastructure.userCreateId); */

                    return (
                        <Table.Row key={infrastructure._links.self.href}>
                            <Table.Cell className='preLine'>
                                <Link to={`/infrastructures/${InfrastructureApi.infrastructureId(infrastructure)}`}
                                    title='Preview'>
                                    {infrastructure.name}
                                    {infrastructure.image && <Image src={infrastructure.image.url} className='infrastructureListOverview'
                                        size='small' bordered rounded />}
                                </Link>
                            </Table.Cell>
                            <Table.Cell className='preLine'>
                                <EtapInfrastuktury infrastructure={infrastructure} />
                            </Table.Cell>
                            <Table.Cell className='preLine'>
                                <JednostkaInfrastuktury infrastructure={infrastructure} />
                            </Table.Cell>
                            <Table.Cell >
                                <OsobaOdpowiedzialna infrastructure={infrastructure} />
                            </Table.Cell>
                            <Table.Cell >
                                <OsobaEwidencjonujaca infrastructure={infrastructure} />
                            </Table.Cell>
                            <Table.Cell className='preLine' textAlign='center' width='2'>
                                {utils.timestampCutSeconds(infrastructure.updateTimestamp)}
                            </Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table.Body>
        )}
    />
)

const EtapInfrastuktury = ({ infrastructure }) => {
    const statusText = InfrastructureApi.statusInfrastruktury(infrastructure)

    return (
        <span className='status_list'>{ statusText }</span>
    )
}

const JednostkaInfrastuktury = ({ infrastructure }) => {
    if (!infrastructure.jednostkaPwr) return null
    return (
        <Popup content={infrastructure.jednostkaPwr.symbol}
            trigger={<span className='jednostka_lista'>{infrastructure.jednostkaPwr.name}</span>} />
    )
}

const OsobaOdpowiedzialna = ({ infrastructure }) => {
    const opiekun = [infrastructure].filter(inf => Array.isArray(infrastructure.pracownikToInfrastructures))
        .map(inf => infrastructure.pracownikToInfrastructures.find(pr => pr.role === 'autor'))
        .find(x => x !== undefined)
    let Opiekun = () =>
        opiekun ?
            <span className='osoba_list'>{opiekun.name}</span>
            : <Popup content='Nie znaleziono osoby'
                trigger={<span className='osoba_list nie_znaleziono'>{infrastructure.tetaOsobaUzytkownik}</span>} />

    return (
        <Opiekun />
    )
}

const OsobaEwidencjonujaca = ({ infrastructure }) => {
    const aparaturowiec = [infrastructure].filter(inf => Array.isArray(infrastructure.pracownikToInfrastructures))
        .map(inf => infrastructure.pracownikToInfrastructures.find(pr => pr.role === 'aparaturowiec'))
        .find(x => x !== undefined)

    const NieZnalezionoOsobyWBazie = () => <Popup content='Nie znaleziono osoby'
        trigger={<span className='osoba_list nie_znaleziono'>{infrastructure.tetaOsobaEwidencja}</span>} />

            return (
        aparaturowiec ?
                    <span className='osoba_list'>{aparaturowiec.name}</span>
                    : < NieZnalezionoOsobyWBazie />
    )
}

export default InfrastructureTable