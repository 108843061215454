import React from 'react'
import { Form, Step, Checkbox } from 'semantic-ui-react'
import InputDatePicker from '../inputs/InputDatePicker.js'
import * as utils from '../../lib/utils.js'
import {
    DropdownTotalBudget, DropdownProjectType, DropdownNationalInternational,
    DropdownTechnologyReadinessLevel, DropdownFinalizedRunning, DropdownDevelopmentStage,
    DropdownProjectsOutput, DropdownIprStatus
} from './Dropdowns.js'
import Dropdown from '../inputs/Dropdown.js'
import DropdownTree from '../inputs/DropdownTree.js'
import IndustryGroup from './IndustryGroup'
import { userService } from '../user/user.service.js';
import TextareaAutosize from 'react-textarea-autosize';
import InfoIconTip from '../inputs/InfoIconTip';

export const Steps = (props) => (
    <Step.Group attached='top' size='tiny' widths={4}>
        <Step active={props.currentStep === 1}
            data-stepnumber={1} title='General information' link
            onClick={props.onStepClick}
        />

        <Step active={props.currentStep === 2}
            data-stepnumber={2} title='Results & TRL' link
            onClick={props.onStepClick}
        />

        <Step active={props.currentStep === 3}
            data-stepnumber={3} title='Project profiling' link
            onClick={props.onStepClick}
        />

        <Step active={props.currentStep === 4}
            data-stepnumber={4} title='Project partners' link
            onClick={props.onStepClick}
        />
    </Step.Group>
)

export const StepGeneralInformation = (props) => (
    <div>
        <Form.TextArea id='form-input-title' label='Title' placeholder='Title' autoFocus rows={1} control={TextareaAutosize}
            name="title" value={props.project.title || ''} onChange={props.onInputChange} required={!props.readOnly}
            error={props.invalidFields.find(o => o.name === 'title') !== undefined}
            readOnly={props.readOnly}
        />
        <Form.TextArea id='form-input-tytul' label='Tytuł' placeholder='Tytuł' autoFocus rows={1} control={TextareaAutosize}
            name="tytul" value={props.project.tytul || ''} onChange={props.onInputChange} readOnly={props.readOnly}
        />

        <Form.Input id='form-input-acronym' label='Acronym' placeholder='Acronym'
            name="acronym" value={props.project.acronym || ''} onChange={props.onInputChange}
            readOnly={props.readOnly}
        />

        <Form.Input id='form-input-projectManager' label='Project manager' placeholder='Project manager'
            name="projectManager" value={props.project.projectManager || ''} onChange={props.onInputChange}
            readOnly={props.readOnly}
        />

        <Form.Group>
            <Form.Field required={!props.readOnly} error={props.invalidFields.find(o => o.name === 'startDate') !== undefined}>
                <label>Start date</label>
                <InputDatePicker name='startDate' value={props.project.startDate}
                    onChange={props.onDateChange}
                    readOnly={props.readOnly}
                />
            </Form.Field>

            <Form.Field required={!props.readOnly} error={props.invalidFields.find(o => o.name === 'endDate') !== undefined}>
                <label>End date</label>
                <InputDatePicker name='endDate' value={props.project.endDate}
                    onChange={props.onDateChange}
                    readOnly={props.readOnly}
                />
            </Form.Field>
        </Form.Group>

        <Form.TextArea id='form-input-projectSummary' label='Project summary' placeholder='Project summary'
            control={TextareaAutosize} rows={1} name="projectSummary"
            value={props.project.projectSummary || ''} onChange={props.onInputChange}
            readOnly={props.readOnly}
        />

        <DropdownProjectType
            onChange={props.onDropdownChange}
            value={utils.multiselectStringToArray(props.project.projectType)}
            error={props.invalidFields.find(o => o.name === 'projectType') !== undefined}
            readOnly={props.readOnly}
            required={!props.readOnly}
        />

        <DropdownNationalInternational
            onChange={props.onDropdownChange}
            value={props.project.nationalInternational}
            error={props.invalidFields.find(o => o.name === 'nationalInternational') !== undefined}
            readOnly={props.readOnly}
            required={!props.readOnly}
        />

        <Form.Input id='form-input-officialProjectNumber' label='Official project number'
            placeholder='Official project number' name="officialProjectNumber"
            value={props.project.officialProjectNumber || ''} onChange={props.onInputChange}
            readOnly={props.readOnly}
        />

        <Form.Input id='form-input-funding-programme' label='Funding programme' placeholder='Funding programme'
            name="fundingProgramme" value={props.project.fundingProgramme || ''} required={!props.readOnly}
            onChange={props.onInputChange}
            error={props.invalidFields.find(o => o.name === 'fundingProgramme') !== undefined}
            readOnly={props.readOnly}
        />

        <DropdownTotalBudget onChange={props.onDropdownChange}
            value={props.project.totalBudget}
            upward
            error={props.invalidFields.find(o => o.name === 'totalBudget') !== undefined}
            readOnly={props.readOnly}
        />

        <Form.Input id='form-input-noProjectPartners' label='No. of project partners'
            placeholder='No. of project partners'
            name="noProjectPartners" value={props.project.noProjectPartners || ''} required={!props.readOnly}
            onChange={props.onInputChange} type='number' min={0}
            error={props.invalidFields.find(o => o.name === 'noProjectPartners') !== undefined}
            readOnly={props.readOnly}
        />

        <Form.Input id='form-input-dataSource' label='Data source' placeholder='Data source'
            name="dataSource" value={props.project.dataSource || ''} onChange={props.onInputChange}
            readOnly={props.readOnly}
        />
        <Form.Field>
            <label>Control status</label>
            <Checkbox toggle label='Authorized' name='authorized' checked={props.project.authorized}
                disabled={props.readOnly || !userService.isAuthorizing()} className='authorized'
                onChange={(event, data) => props.onProjectChange({ authorized: data.checked })} />
        </Form.Field>
    </div>
)

export const StepResultsAndTrl = (props) => (
    <div>
        <DropdownFinalizedRunning
            onChange={props.onDropdownChange}
            value={props.project.finalizedRunning}
            error={props.invalidFields.find(o => o.name === 'finalizedRunning') !== undefined}
            readOnly={props.readOnly}
        />

        <DropdownDevelopmentStage
            onChange={props.onDropdownChange}
            value={props.project.developmentStage}
            error={props.invalidFields.find(o => o.name === 'developmentStage') !== undefined}
            readOnly={props.readOnly}
        />

        <DropdownTechnologyReadinessLevel
            onChange={props.onDropdownChange}
            value={props.project.technologyReadinessLevel}
            error={props.invalidFields.find(o => o.name === 'technologyReadinessLevel') !== undefined}
            readOnly={props.readOnly}
            required={!props.readOnly}
        />

        <DropdownProjectsOutput
            onChange={props.onDropdownChange}
            value={utils.multiselectStringToArray(props.project.projectsOutput)}
            error={props.invalidFields.find(o => o.name === 'projectsOutput') !== undefined}
            readOnly={props.readOnly}
            required={!props.readOnly}
        />

        <Form.TextArea id='form-input-projectsOutputDesc'
            label="Project's output" placeholder="Project's output"
            control={TextareaAutosize} rows={1}
            name="projectsOutputDesc"
            value={props.project.projectsOutputDesc || ''}
            onChange={props.onInputChange}
            readOnly={props.readOnly}
        />

        <DropdownIprStatus
            onChange={props.onDropdownChange}
            value={props.project.iprStatus}
            error={props.invalidFields.find(o => o.name === 'iprStatus') !== undefined}
            readOnly={props.readOnly}
            required={!props.readOnly}
        />

        <Form.Input id='form-input-webLink'
            label='Web link' placeholder='Web link'
            name="webLink" value={props.project.webLink || ''}
            onChange={props.onInputChange}
            readOnly={props.readOnly}
        />
    </div>
)

export const StepProjectProfiling = (props) => (
    <div className='stepContent'>
        <fieldset className='ui segment'>
            <legend>Additive Manufacturing & 3D-Printing</legend>
            <Dropdown
                required={!props.readOnly}
                onChange={props.onDropdownChange}
                value={props.project.additiveManufacturing3dPrinting}
                label='Additive Manufacturing & 3D-Printing'
                name='additiveManufacturing3dPrinting'
                dataUrl='/data/project_yes_no_not_known.json'
                infotip={<InfoIconTip content='Laser technologies, lithography, Inkjet, FDM, printing technologies' visible={!props.readOnly} />}
                error={props.invalidFields.find(o => o.name === 'additiveManufacturing3dPrinting') !== undefined}
                readOnly={props.readOnly}
            />
            <DropdownTree
                required={props.project.additiveManufacturing3dPrinting === 'Yes' && !props.readOnly}
                name='additiveManufacturingKpaTechnologyKeywords'
                label='KPA – Technology Keywords'
                onChange={props.onDropdownChange}
                dataUrl='/api/dictionary/additive_manufacturing_kpa_technology_keywords/dropdown_tree'
                value={utils.multiselectStringToArray(props.project.additiveManufacturingKpaTechnologyKeywords)}
                error={props.invalidFields.find(o => o.name === 'additiveManufacturingKpaTechnologyKeywords') !== undefined}
                readOnly={props.readOnly}
                visible={props.project.additiveManufacturing3dPrinting === 'Yes'}
            />
            <DropdownTree
                required={props.project.additiveManufacturing3dPrinting === 'Yes' && !props.readOnly}
                name='additiveManufacturingKpaMarketKeywords'
                label='KPA – Market Keywords'
                onChange={props.onDropdownChange}
                dataUrl='/api/dictionary/additive_manufacturing_kpa_market_keywords/dropdown_tree'
                value={utils.multiselectStringToArray(props.project.additiveManufacturingKpaMarketKeywords)}
                error={props.invalidFields.find(o => o.name === 'additiveManufacturingKpaMarketKeywords') !== undefined}
                readOnly={props.readOnly}
                visible={props.project.additiveManufacturing3dPrinting === 'Yes'}
            />
        </fieldset>

        <fieldset className='ui segment'>
            <legend>Micro-&Nanotechnology-related Processes & Materials</legend>
            <Dropdown
                required={!props.readOnly}
                onChange={props.onDropdownChange}
                value={props.project.microNanoTechnology}
                label='Micro-&Nanotechnology-related Processes & Materials'
                name='microNanoTechnology'
                dataUrl='/data/project_yes_no_not_known.json'
                infotip={<InfoIconTip content={`Nanoparticle-filled materials, novel nanomaterials, 
                            knowledge management & databases for micro-& nanotechnology-related processes & materials, 
                            OHS issues related to nanosafety`} visible={!props.readOnly} />}
                error={props.invalidFields.find(o => o.name === 'microNanoTechnology') !== undefined}
                readOnly={props.readOnly}
            />
            <DropdownTree
                required={props.project.microNanoTechnology === 'Yes' && !props.readOnly}
                name='microNanotechnologyKpaTechnologyKeywords'
                label='KPA – Technology Keywords'
                onChange={props.onDropdownChange}
                dataUrl='/api/dictionary/micro_nanotechnology_kpa_technology_keywords/dropdown_tree'
                value={utils.multiselectStringToArray(props.project.microNanotechnologyKpaTechnologyKeywords)}
                error={props.invalidFields.find(o => o.name === 'microNanotechnologyKpaTechnologyKeywords') !== undefined}
                readOnly={props.readOnly}
                visible={props.project.microNanoTechnology === 'Yes'}
            />
            <DropdownTree
                required={props.project.microNanoTechnology === 'Yes' && !props.readOnly}
                name='microNanotechnologyKpaMarketKeywords'
                label='KPA – Market Keywords'
                onChange={props.onDropdownChange}
                dataUrl='/api/dictionary/micro_nanotechnology_kpa_market_keywords/dropdown_tree'
                value={utils.multiselectStringToArray(props.project.microNanotechnologyKpaMarketKeywords)}
                error={props.invalidFields.find(o => o.name === 'microNanotechnologyKpaMarketKeywords') !== undefined}
                readOnly={props.readOnly}
                visible={props.project.microNanoTechnology === 'Yes'}
            />
        </fieldset>

        <IndustryGroup project={props.project}
            onProjectChange={props.onProjectChange}
            invalidFields={props.invalidFields}
            readOnly={props.readOnly}
        />
    </div>
)