import React, { useState, useEffect } from 'react'
import { Route, Link, Switch } from 'react-router-dom';
import { Icon, Pagination, Form, Table, Button, Modal, Label, TextArea, Input } from 'semantic-ui-react'
import _ from 'lodash';
import { useGetPermisesAllQuery, useUpdatePermisesPatchMutation, usePermisesProcessUrlMutation } from '../../redux/PermisesApi'
import { useGetPracownicyPwrAllQuery } from '../../redux/PracownicyPwrApi'
import './permises.css'


const PremisesList = () => {
    //console.log('PracownicyList', useGetPracownicyPwrPageAllQuery)
    //const qResult = useGetPracownicyPwrPageAllQuery()
    //console.log('PracownicyList qResult', qResult)
    const { data, error, isLoading } = useGetPermisesAllQuery({})
    //const { data, error, isLoading } = useGetPracownicyPwrAllQuery()
    //console.log('PremisesList')

    return (
        <div className='infrastructure'>
            <form>
            {error ? (
                <>Oh no, there was an error</>
            ) : isLoading ? (
                <>Loading...</>
            ) : data ? (
                <div className='projectList'>
                                <Header />
                    {/* <SearchPanel setSearchRequest={setSearchRequest} /> */}
                            {/* <TabelaPermises listaPracownikow={data} 
                            //     sortable={{ sortColumn, setSortColumn, sortDesc, setSortDesc }} 
                            // pageable={{ page, totalCount, totalPages, onPageChange }} 
                            /> */}
                                <TabelaPremisesAll listaPremises={data} />
                </div>
            ) : null}
            </form>
        </div>
    )
}

const Header = ({ listaPremises, pageable, sortable }) => {
    const [processUrl, processUrlResult] = usePermisesProcessUrlMutation()

    const handleProcessUrl = e => {
        const url = document.getElementById('url_input').value
        console.log('handleProcessUrl', url)
        processUrl(url)
        //upPermises(e.target.name, e.target.value)
    }
   
    return (
        <div className='premises_header'>
            <input type='text' className='url_input' name='url' id='url_input' />
            <button type='button' onClick={handleProcessUrl}>procesuj url</button>
        </div>
    )
}

const TabelaPremisesAll = ({ listaPremises, pageable, sortable }) => {
    const [rankDialogTrigger, setRankDialogTrigger] = useState(null)
    const openRankDialog = (premises) => {
        setRankDialogTrigger(premises);
    }

    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Symbol</Table.HeaderCell>
                    <Table.HeaderCell>Rank</Table.HeaderCell>
                    <Table.HeaderCell>Opis</Table.HeaderCell>
                    <Table.HeaderCell>Dane</Table.HeaderCell>
                    <Table.HeaderCell>Link</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    listaPremises.map(premises =>
                        <Table.Row key={premises.id}>
                            <Table.Cell>
                                {/* <Button content='lista'
                                    primary as={Link} to={`/pracownicy/${pracownik.id}`} >
                                    {pracownik.name}
                                </Button> */}
                                <Link to={`/permises/${premises.id}`}
                                    title='Preview'>
                                    {premises.symbol}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>
                                <button type='button' onClick={() => openRankDialog(premises)}>{premises.rank || '?'}</button>
                            </Table.Cell>
                            <Table.Cell><PremisesListView permisesServerState={premises} /></Table.Cell>
                            <Table.Cell>
                                <div className='cechy_para'><div className='cechy_label'>cena</div><div className='cechy_value'>{premises.cena}</div></div>
                                <div className='cechy_para'><div className='cechy_label'>pow.</div><div className='cechy_value'>{premises.powierzchnia}</div></div>
                                <div className='cechy_para'><div className='cechy_label'>za m2</div><div className='cechy_value'>{premises.zaM2}</div></div>
                                <div className='cechy_para'><div className='cechy_label'>tel</div><div className='cechy_value'>{premises.telefon}</div></div>
                            </Table.Cell>
                            <Table.Cell><PremisesLinkView permisesServerState={premises} /></Table.Cell>
                        </Table.Row>
                    )
                }
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='5'>
                        {rankDialogTrigger && <RankDialog rankDialogTrigger={rankDialogTrigger} permisesServerState={rankDialogTrigger} /> }
                        {rankDialogTrigger ? rankDialogTrigger.id : 'no triger'}
                        {/* {data && data.length}
                            {Object.entries(data[1]).map(item => <span> {item } </span>)}
                        <Menu floated='right' pagination>
                            <Menu.Item as='a' icon>
                                <Icon name='chevron left' />
                            </Menu.Item>
                            <Menu.Item as='a'>1</Menu.Item>
                            <Menu.Item as='a'>2</Menu.Item>
                            <Menu.Item as='a'>3</Menu.Item>
                            <Menu.Item as='a'>4</Menu.Item>
                            <Menu.Item as='a' icon>
                                <Icon name='chevron right' />
                            </Menu.Item>
                        </Menu> */}
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    )
}
const PremisesListView = ({ permisesServerState }) => {
    const [permises, setPermises] = React.useState(permisesServerState)
    const [updatePermises, updatePermisesResult] = useUpdatePermisesPatchMutation()
    const handleSave = () => {
        updatePermises(permises)
    }
    const handleChange = e => {
        console.log('handleChange', e.target.name, e.target.value)
        upPermises(e.target.name, e.target.value)
    }
    const upPermises = (name, value) => {
        setPermises(Object.assign({}, permises, { [name]: value }))
    }

    return (
        <div className='premises_list_view'>
            {/* <div className='premises_list_view_content'>
                <input type='text' className='rank_input' name='rank' value={permises.rank || ''} 
                    onChange={handleChange}/>
                <button type='button' onClick={handleSave}>zapisz</button>
            </div> */}
            <div className='premises_list_view_content'>
                <div>{permises.title}</div>
                <div className='list_uwagi'>{permises.uwagi}</div>
                <div>{permises.description}</div>
            </div>
        </div>
    )
}

const logoPathFromUrl = (url) => {
    switch (true) {
        case url.includes("otodom"): return '/premises/otodom_logo_2021_black.svg'
        case url.includes("adresowo"): return '/premises/logo_adresowo.svg'
        case url.includes("olx"): return '/premises/olx_logo.jpg'
    }
    // if (url.includes("otodom")) {
    //     return '/premises/otodom_logo_2021_black.svg'
    // }
    // if (url.includes("adresowo")) {
    //     return '/premises/logo_adresowo.svg'
    // }
    // if (url.includes("olx")) {
    //     return '/premises/olx_logo.jpg'
    // }
    return null
}

const PremisesLinkView = ({ permisesServerState }) => {
    const { url } = permisesServerState
    if (!url) {
        return <></>
    }
    const logoPath = logoPathFromUrl(url)
    if (logoPath) {
        return (
            <div >
                <a href={permisesServerState.url} target='_blank' title={permisesServerState.url}>
                    <img width={70} src={logoPath}></img>
                </a>
            </div>
        )
    }

    return (
        <div >
            <a href={permisesServerState.url} target='_blank' title={permisesServerState.url}>
                Link                
            </a>
        </div>
    )
}

const TabelaPremises = ({ listaPracownikow, pageable, sortable }) => {
    const { page, totalCount, totalPages, onPageChange } = pageable
    const { sortColumn, setSortColumn, sortDesc, setSortDesc } = sortable
    const onSort = (column) => {
        if (sortColumn === column) setSortDesc(!sortDesc)
        else {
            setSortColumn(column)
            setSortDesc(false)
        }
    }
    const isSortedByColumn = column => {
        if (sortColumn != column) return null
        if (sortDesc) return 'descending'
        else return 'ascending'
    }
    return (
        <Table celled sortable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell onClick={evt => onSort('name')} sorted={isSortedByColumn('name')}>
                        Pracownik
                    </Table.HeaderCell>
                    <Table.HeaderCell onClick={evt => onSort('jednostkaNad')} sorted={isSortedByColumn('jednostkaNad')}>
                        Jednostka nadrzędna
                    </Table.HeaderCell>
                    <Table.HeaderCell onClick={evt => onSort('jednostkaPwr')} sorted={isSortedByColumn('jednostkaPwr')}>
                        Jednostka
                    </Table.HeaderCell>
                    <Table.HeaderCell>E-mail</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {
                    listaPracownikow.map(pracownik =>
                        <Table.Row key={pracownik.id}>
                            <Table.Cell>
                                {/* <Button content='lista'
                                    primary as={Link} to={`/pracownicy/${pracownik.id}`} >
                                    {pracownik.name}
                                </Button> */}
                                <Link to={`/pracownicy/${pracownik.id}`}
                                    title='Preview'>
                                    {pracownik.name}
                                </Link>
                            </Table.Cell>
                            <Table.Cell>{pracownik.jednostkaNad}</Table.Cell>
                            <Table.Cell>{pracownik?.jednostkaPwr?.name}</Table.Cell>
                            <Table.Cell>{pracownik.email}</Table.Cell>
                        </Table.Row>
                    )
                }
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan='3'>
                        <Pagination
                            firstItem={null}
                            lastItem={null}
                            prevItem={{ content: <Icon name='angle left' />, icon: true }}
                            nextItem={{ content: <Icon name='angle right' />, icon: true }}
                            totalPages={totalPages}
                            onPageChange={onPageChange}
                            activePage={page + 1}
                        />
                        <div>
                            Rekordów {totalCount}
                        </div>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    )
}

const RankDialog = (props) => {
    const { rankDialogTrigger, permisesServerState } = props
    const [open, setOpen] = React.useState(false)
    const [premises, setPremises] = React.useState(permisesServerState)
    const [updatePermises, updatePermisesResult] = useUpdatePermisesPatchMutation()
    useEffect(() => {
        if (rankDialogTrigger) setOpen(true)
        setPremises(permisesServerState)
    }, [rankDialogTrigger]);

    const handleClose = () => {
        setOpen(false)
    }
    const handleSave = () => {
        updatePermises(premises)
        setOpen(false)
    }
    const handleOdswiez = () => {
        fetch("/api/rest/premises-refresh/" + premises.id)
        setOpen(false)
    }
    const updateRank = (delta) => {
        upPermises('rank', premises.rank + delta)
    }
    const handleChange = e => {
        console.log('handleChange', e.target.name, e.target.value)
        upPermises(e.target.name, e.target.value)
    }
    const handleTextareaChange = (e, data) => {
        console.log('handleTextareaChange', e, data)
        upPermises(data.name, data.value)
    }
    const upPermises = (name, value) => {
        setPremises(Object.assign({}, premises, { [name]: value }))
    }
    if (!rankDialogTrigger) return <></>

    return (
        <Modal dimmer={{ inverted: true }} closeIcon onClose={handleClose} open={open} >
            <Modal.Header>
                <Input name='title' value={premises.title || ''} onChange={(event, data) => upPermises(data.name, data.value)}
                    className='title_input'/>
            </Modal.Header>
            <Modal.Content>
                <div className='edit_panel'>
                    <div className='edit_panel_center'>
                        <div className='rank_update'>
                            <Button onClick={() => updateRank(-100)} color='red' size='tiny'>-100</Button>
                            <Button onClick={() => updateRank(-10)} color='red' size='tiny'>-10</Button>
                            <Button onClick={() => updateRank(-1)} color='red' size='tiny'>-1</Button>
                            <Label size='massive'>{premises.rank}</Label>
                            <Button onClick={() => updateRank(1)} color='green' size='tiny'>1</Button>
                            <Button onClick={() => updateRank(10)} color='green' size='tiny'>10</Button>
                            <Button onClick={() => updateRank(100)} color='green' size='tiny'>100</Button>
                        </div>
                        <div>
                            <TextArea name='uwagi' value={premises.uwagi || ''} onChange={handleTextareaChange}
                                style={{ minHeight: 100 }} />
                        </div>
                    </div>
                    <div className='edit_panel_right'>
                        <div>
                            <Input label='cena' name='cena' value={premises.cena || ''} className='input_number'
                                onChange={handleChange} />
                        </div>
                        <div>
                            <Input label='za_m2' name='zaM2' value={premises.zaM2 || ''} className='input_number'
                                onChange={handleChange} />
                        </div>
                        <div>
                            <Input label='pow' name='powierzchnia' value={premises.powierzchnia || ''} className='input_number'
                                onChange={handleChange} />
                        </div>
                        <div>
                            <Input label='tel' name='telefon' value={premises.telefon || ''} className='input_number'
                                onChange={handleChange} />
                        </div>
                    </div>
                </div>
                {/* <Message className='validationErrors'
                    error
                    size='small'
                    header='Następujące pola są niewypełnione lub zawierają błędy'
                    list={invalidFields.map(o => o.validationClickableError)}
                    onDismiss={handleDismissError}
                /> */}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => handleOdswiez()} >
                    <Icon name='refresh' /> Odśwież
                </Button>
                <Button onClick={() => handleSave()} color='green'>
                    <Icon name='check' /> Zapisz
                </Button>
                <Button onClick={handleClose} color='red'>
                    <Icon name='remove' /> Anuluj
                </Button>
            </Modal.Actions>
            <Modal.Actions>
            </Modal.Actions>
        </Modal>
    )

}

export default PremisesList