import React from 'react'
import './Map.css'
import MapView from './MapView.js'
import { List, Form, Button, Container, Icon, Message, Segment } from 'semantic-ui-react'
import client from '../../lib/client.js'
import { modules, pathToApi } from '../../lib/connectionConst'
import Dropdown from '../inputs/Dropdown.js'
import { Route, Link } from 'react-router-dom'
import OrganizationCreateForm from '../organization/OrganizationCreateForm.js'
import {
    DropdownProjectType, DropdownNationalInternational, DropdownTechnologyReadinessLevel,
    DropdownProjectsOutput, DropdownIprStatus
} from '../project/Dropdowns.js'
import ReactGA from 'react-ga'

class MapComponent extends React.Component {

    state = {
        organizations: [],
        clickedOrg: null,
        isLoading: false
    }

    componentDidMount() {
        ReactGA.pageview('/map')
        this.setState({ isLoading: true });
        client(pathToApi(modules.ORGANIZATION)+`/search/findAll?projection=withGeoSearchData&sort=nameEnglish,asc`)
            .then(response => {
                let data = response.entity;
                let organizations = data._embedded.organizations;

                this.setState({ organizations });
            }).finally(() => this.setState({ isLoading: false }));
    }

    handleOrganizationClick = (evt, org) => {
        //console.log('list click', org);
        if (org.geoSearchData !== null) {
            this.setState({ clickedOrg: org });
        }
    }

    fetchOrganizations = (filter) => {
        //console.log(this.props);
        this.setState({ isLoading: true, organizations: [] });

        client({
            method: 'POST',
            path: pathToApi(modules.ORGANIZATION)+'/search/by_map_filter',
            entity: filter,
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            // console.log(response);
            this.setState({ organizations: response.entity });
        }).finally(() =>
            this.setState({ isLoading: false })
        )
    }

    render() {
        let organizationsWithGeoData = this.state.organizations.filter(o => o.geoSearchData !== null);

        return (
            <div>
                <Route path="/map/organization/:organizationId"
                    render={(props) =>
                        <OrganizationCreateForm {...props} readOnly={true}
                            title={<span>Preview organization</span>}
                            onClose={() => props.history.push('/map')}
                            onSubmit={() => props.history.push('/map')}
                        />
                    }>
                </Route>

                <div className='map-component'>
                    <div className='map-controls'>
                        <OrgFilters
                            onFetchOrganizations={this.fetchOrganizations}
                        />
                        <OrgList
                            organizations={this.state.organizations}
                            onOrganizationClick={this.handleOrganizationClick}
                            isLoading={this.state.isLoading}
                        />
                    </div>

                    <MapView
                        organizations={organizationsWithGeoData}
                        clickedOrg={this.state.clickedOrg}
                        isLoading={this.state.isLoading}
                    />
                </div>
            </div>
        )
    }
}

const ListOrganizationItem = (props) => {
    const { org } = props;
    let cityAndCountry = [org.city, org.country].filter(o => o && o !== '').join(', ');

    return (
        <div>
            <span className='nameEnglish' disabled={org.geoSearchData === null}
                onClick={(e) => props.onOrganizationClick(e, org)}>
                {org.nameEnglish}
            </span>
            <List.Content floated='right'>
                <span className='cityAndCountry'>
                    {cityAndCountry}
                </span>
                <Button.Group basic compact size='tiny'>
                    <Button icon='edit' compact
                        title='Details' as={Link} to={`/map/organization/${org.id}`}
                    />
                    <Button icon='location arrow' compact disabled={org.geoSearchData === null}
                        title='Go to location' onClick={(e) => props.onOrganizationClick(e, org)}
                    />
                </Button.Group>
            </List.Content>
        </div>
    )
}

class OrgList extends React.Component {

    render() {
        let orgs = this.props.organizations.map(o =>
            <List.Item key={o.id}>
                <ListOrganizationItem org={o} onOrganizationClick={this.props.onOrganizationClick} />
            </List.Item>
        )

        return (
            orgs.length === 0 && !this.props.isLoading ?
                <Message info header='No results found.' />
                :
                <Segment size='small' verticalAlign='middle' basic loading={this.props.isLoading} as={List}
                    celled selection className='org-list'>
                    {orgs}
                </Segment>
        )
    }
}

class OrgFilters extends React.Component {
    state = {
        filter: {
            competencesOrAnd: 'and'
        }
    }

    handleFilterChange = (evt, changes) => {
        const value = changes.selectedValues ? changes.selectedValues : changes.value;
        const name = changes.name;

        let filter = Object.assign({ ...this.state.filter }, { [name]: value });
        this.setState({ filter });

        //console.log(changes, filter);
    }

    handleFindOrganizationsClick = () => {
        this.props.onFetchOrganizations(this.state.filter);
    }

    render() {
        return (
            <Form className='org-filters' size='tiny'>
                <Container textAlign='center' as='h5' className='defineParametersLabel'>
                    Define the project parameters to search the organization
                </Container>

                <Form.Group widths='equal'>
                    <DropdownProjectType
                        compact
                        onChange={this.handleFilterChange}
                    />
                    <DropdownNationalInternational
                        compact
                        onChange={this.handleFilterChange}
                        multiple
                    />
                </Form.Group>

                <Form.Group widths='equal'>
                    <DropdownTechnologyReadinessLevel
                        compact
                        onChange={this.handleFilterChange}
                        multiple
                    />
                    <DropdownProjectsOutput
                        compact
                        onChange={this.handleFilterChange}
                        multiple
                    />
                </Form.Group>

                <Form.Group widths='equal'>
                    <DropdownIprStatus
                        compact
                        onChange={this.handleFilterChange}
                        multiple
                    />
                    <Dropdown
                        label='Additive Manufacturing & 3D-Printing'
                        name='additiveManufacturing3dPrinting'
                        compact
                        onChange={this.handleFilterChange}
                        dataUrl='/data/project_yes_no_not_known.json'
                    />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Dropdown
                        label='Micro-&Nanotechnology-related Processes & Materials'
                        placeholder='Micro-&Nanotechnology'
                        name='microNanoTechnology'
                        compact
                        onChange={this.handleFilterChange}
                        dataUrl='/data/project_yes_no_not_known.json'
                    />
                    <Dropdown
                        label='Industry 4.0'
                        name='industry40'
                        compact
                        onChange={this.handleFilterChange}
                        dataUrl='/data/project_yes_no_not_known.json'
                    />
                </Form.Group>

                <Form.Group style={{ alignItems: 'flex-end' }}>
                    <Form.Input
                        label='Organization competences'
                        placeholder='Organization competences'
                        name='competencesDesc'
                        onChange={this.handleFilterChange}
                        width={10}
                    />
                    <CompetencesSearchModeToggler
                        mode={this.state.filter.competencesOrAnd}
                        onFilterChange={this.handleFilterChange}
                    />
                </Form.Group>

                <Container textAlign='center'>
                    <Button primary icon onClick={this.handleFindOrganizationsClick}>
                        <Icon name='search' /> Find organizations
                    </Button>
                </Container>
            </Form>
        )
    }
}

class CompetencesSearchModeToggler extends React.Component {

    handleChange = (evt, data) => {
        this.props.onFilterChange(evt, { name: data.name, value: data.checked ? 'and' : 'or' });
    }

    render() {
        const { mode } = this.props;
        let label = <label>Filter by project parameters <b>{mode}</b> organization competences</label>;

        return (
            <Form.Checkbox
                label={label}
                width={6}
                name='competencesOrAnd'
                onChange={this.handleChange}
                defaultChecked={mode === 'and'}
            />
        )
    }
}

export default MapComponent;