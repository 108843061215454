import React from 'react'
import { Image } from 'semantic-ui-react'
import logo from '../assets/pwr.png'
import microscope from '../assets/microscope.png'
import './AppHeader.css'

const AppHeader = () => (
    <header className='appHeader'>
        {/* <div>
            <a href='http://www.interreg-central.eu/' className='interreg'>interreg central europe</a>
        </div> */}
        <div className='headerNazwaInstytucji' > {/* fontFamily: "proxima-nova", */}
            Centrum Innowacji i Biznesu Politechniki Wrocławskiej
        </div>
        <div>
            {/* <span className='priority'>
                <img src={microscope} className='microscope' alt='' />
                priority: innovation and knowledge development
            </span> */}
            {/* <Image className='synergyLogo' src={logo} as='a'
                href='https://pwr.edu.pl/'
            /> */}
            <Image className='synergyLogo' src='https://pwr.edu.pl/themes/_system/images/logo_top_ie8.png' as='a'
                href='https://pwr.edu.pl/' alt='link do strony Politechniki Wrocławskiej'
            />
            
        </div>
    </header>
)

export default AppHeader