import React, { useState } from 'react';
import InfrastructureSearch from './InfrastructureSearch';
import InfrastructureList from './InfrastructureList';
import InfrastructureMap from './InfrastructureMap';
import './Infrastructure.css';
import * as utils from '../../lib/utils.js';
import { modules, pathToApi } from '../../lib/connectionConst'
import { Icon, Button } from 'semantic-ui-react';
import { Switch } from 'antd';
import { userService } from '../user/user.service.js';

export const STORAGE_KEY_VIEW_MODE = 'Synergy::Infrastructure::viewMode';

const Infrastructure = props => {
    const loggedUser = userService.loggedUser();
    const [infrastructureSearchFields, setInfrastructureSearchFields] = useState({ userId: loggedUser.id, userEmail: loggedUser.email })
    const [searchHash, setSearchHash] = useState(utils.hashCodeObject(infrastructureSearchFields))
    const [viewMode, setViewMode] = useState(sessionStorage.getItem(STORAGE_KEY_VIEW_MODE) || 'list')

    const handleInfrastructureSearchFieldsChange = changes => {
        console.log('handleInfrastructureSearchFieldsChange', changes)
        const infrastructure = Object.assign({ ...infrastructureSearchFields }, changes);
        setInfrastructureSearchFields(infrastructure);
    }

    const handleSearchInfrastructure = () => {
        setSearchHash(utils.hashCodeObject(infrastructureSearchFields))
    }

    const apiDataFetchUrl = pathToApi(modules.INFRASTRUCTURE) +`/search/by_infrastructure_filter_hateoas_paged?searchHash=${searchHash}` +
        `&sort=name,asc&size=${viewMode === 'list' ? 20 : 999}`

    return (
        <div className='infrastructure'>
            <InfrastructureSearch
                infrastructureSearchFields={infrastructureSearchFields}
                onInfrastructureSearchFieldsChange={handleInfrastructureSearchFieldsChange}
                searchInfrastructure={handleSearchInfrastructure}
                searchHash={searchHash}
                viewMode={viewMode}
            />

            {
                viewMode === 'map' ?
                    <InfrastructureMap
                        apiDataFetchUrl={apiDataFetchUrl}
                        searchEntity={infrastructureSearchFields}
                        viewMode={viewMode}
                        handleViewModeChange={setViewMode}
                    />
                    :
                    <InfrastructureList
                        apiDataFetchUrl={apiDataFetchUrl}
                        searchEntity={infrastructureSearchFields}
                        viewMode={viewMode}
                        handleViewModeChange={setViewMode}
                    />
            }
        </div>
    )
}

export const ToggleViewMode = ({ viewMode, onChange }) => {
    const handleClick = currentViewMode => {
        onChange(currentViewMode);
        sessionStorage.setItem(STORAGE_KEY_VIEW_MODE, currentViewMode);
    }

    return (
        <div className='infrastructureViewModeToggle'>
            <Button.Group size='small'>
                <Button icon='list' content='List' active={viewMode === 'list'} onClick={() => handleClick('list')} 
                    compact primary={viewMode === 'list'} />
                <Button icon='location arrow' content='Map' active={viewMode === 'map'} onClick={() => handleClick('map')} 
                    compact primary={viewMode === 'map'} />
            </Button.Group>
        </div>
    )
}

export const UserManualButton = props =>
    <Button title='User manual' color='yellow' circular className='userManualBtn'
        onClick={() => { window.open('/infrastructure-sharing-manual.pdf') }}
    >Manual</Button>

export default Infrastructure