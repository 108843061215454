import React, { useState, useEffect } from 'react'
import { Button } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import './PwrLogin.css'

const PwrLogin = ({setLoggedUser}) => {
    const [authorized, setAuthorized] = useState(false);
    const [loggedAs, setLoggedAs] = useState("-");
    const [oauthuser, setOauthuser] = useState({ hits: [] });
    const storage = sessionStorage;

    useEffect(() => {
        async function fetchData() {
            const result = await axios(
                '/authentication_principals/user',
            );
            const authorized = result.data.authorized == 'true'
            //console.log('/authentication_principals/user', authorized, result.data)
            setAuthorized(authorized);
            setLoggedAs(result.data.name);
            setOauthuser(result.data.oauthuser);
        }
        fetchData()
    }, []);

    //console.log('PwrLogin setLoggedUser', setLoggedUser)
    const developmentLogin = async (user_role) => {
        //console.log('PwrLogin/developmentLogin as ', user_role)
        const result = await axios(
            '/data/' + user_role,
        );
        const user = result.data
        setLoggedAs(user.name)
        const authorized = user.activated == 'true'
        setAuthorized(authorized);
        //console.log('PwrLogin/developmentLogin user ', user)
        storage.setItem('user', JSON.stringify(user));
        setLoggedUser && setLoggedUser(user)        
    }
    const getLoggedUser = async () => {
        const result = await axios.post('/authentication_principals/logged_user');
        //console.log('LoggedUser', result.data)
        if (result.data) {
            const user = result.data
            storage.setItem('user', JSON.stringify(user));
            setLoggedUser(user)        
        }
    }

    const goTo = (link) => {
        window.location.href = '/oauth2/authorization/pwr';
    }
    return (
        <div id="pwr_login">
            <div id='wymagane_uwierzytelnienie'>Wymagane uwierzytelnienie</div>
            <div id='logowanie_domenowe'>Logowanie do systemu następuje poprzez logowanie domenowe PWr</div>
            {/* <span>api  {process.env.REACT_APP_PATH_TO_API_INFRASTRUCTURE}</span>
            <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small>
            <br /> */}
            {/* <Button onClick={() => goTo('/oauth2/authorization/pwr')} content='Przejdź do strony uwierzytelniania POLITECHNIKI WROCŁAWSKIEJ' /> */}
            {/* <br /> */}
            {/* <button className='login_button' onclick="() => window.location.href='/oauth2/authorization/pwr';">
                Przejdź do strony uwierzytelniania POLITECHNIKI WROCŁAWSKIEJ
            </button> */}
            
            <a className='login_link' href='/oauth2/authorization/pwr'>Przejdź do strony uwierzytelniania POLITECHNIKI WROCŁAWSKIEJ</a>
            <br /><br />
            {
                process.env.NODE_ENV == 'development'
                &&
                <div>
                    <fieldset className='development'>
                        <legend>development section</legend>
                            Authorized {authorized ? "as " + loggedAs : "no"}
                            <br />
                        <div>
                            Log as
                            <button onClick={() => developmentLogin('dev_user.json')}>dev Login</button>
                            <button onClick={() => developmentLogin('dev_user_admin.json')}>dev Log admin</button>
                            <button onClick={() => developmentLogin('dev_user_paulina_mayer.json')}>dev Log paulina.mayer</button>
                            <button onClick={() => developmentLogin('dev_user_zbigniew_kedzia.json')}>dev Log zbigniew.kedzia</button>
                            <button onClick={() => developmentLogin('dev_user_edward.chlebus.json')}>dev Log edward.chlebus</button>
                            <button onClick={() => developmentLogin('dev_user_dorota.taraszewska.json')}>dev Log dorota.taraszewska</button>
                        </div>
                        {authorized ? "user principals " + oauthuser : ""}
                            <Button as={Link} to={'/success-login'} content='success-login' />
                        <Button as={Link} to={'/infrastructures'} content='infrastructures' />
                        <div><button onClick={() => getLoggedUser()}>LoggedUser</button></div>
                    </fieldset>
                </div>
            }
        </div>
    )
}

export default PwrLogin