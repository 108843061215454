import React, { useEffect, useState } from "react";
import { Form, Button } from "semantic-ui-react";
import TextareaAutosize from 'react-textarea-autosize';

const OrgWspolpracaEdit = ({ wspolpraca, organization, zachowajWspolprace}) => {
    const {readOnly} = organization
    const [wspolpracaLocal, setWspolpracaLocal] = useState(wspolpraca)
    const onSubmit = (data) => {
        console.log('onSubmit', data);
    }
    const handleZachowaj = () => {
        console.log('handleZachowaj', wspolpracaLocal);
        zachowajWspolprace(wspolpracaLocal)
    }
    const handleAnuluj = () => zachowajWspolprace(null)

    const handleInputChange = ({ target }) => {
        const { value, name } = target;
        console.log('handleInputChange', name, value)

        const wspol = { ...wspolpracaLocal };
        wspol[name] = value;

        setWspolpracaLocal(wspol);
    }
    const canSave = wspolpracaLocal.data_start && wspolpracaLocal.title

    return (
        <div>
            <fieldset className='ui segment'>
            <Form.Group>
                <Form.Input id='form-input-data_start' label={'Data rozpoczęcia'}
                placeholder={'Data uruchomienia'}
                required={!readOnly}
                name="data_start" value={wspolpracaLocal.data_start || ''}
                type='date'
                onChange={e => handleInputChange(e, handleInputChange)}
                readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'data_start') !== undefined}
            />
                <Form.Input id='form-input-time_start' label={'Godzina rozpoczęcia'}
                        placeholder={'Godzina rozpoczęcia'}
                    //required={!readOnly}
                    name="time_start" value={wspolpracaLocal.time_start || ''}
                    type='time'
                    onChange={e => handleInputChange(e, handleInputChange)}
                //readOnly={readOnly}
                //error={invalidFields.find(o => o.name === 'time_start') !== undefined}
                />
            </Form.Group>
            <Form.Group>
                <Form.Input id='form-input-data_data_end' label={'Data zakończenia'}
                    placeholder={'Data zakończenia'}
                    //required={!readOnly}
                    name="data_end" value={wspolpracaLocal.data_end || ''}
                    type='date'
                    onChange={e => handleInputChange(e, handleInputChange)}
                //readOnly={readOnly}
                //error={invalidFields.find(o => o.name === 'data_end') !== undefined}
                />
                <Form.Input id='form-input-time_end' label={'Godzina zakonczenia'}
                    placeholder={'Godzina zakonczenia'}
                    //required={!readOnly}
                    name="data_end" value={wspolpracaLocal.time_end || ''}
                    type='time'
                    onChange={e => handleInputChange(e, handleInputChange)}
                //readOnly={readOnly}
                //error={invalidFields.find(o => o.name === 'time_end') !== undefined}
                />
            </Form.Group>
            <Form.Input id='form-input-data_title' label={'Tytuł'}
                placeholder={'Tytuł'}
                required={!readOnly}
                name="title" value={wspolpracaLocal.title || ''}
                onChange={e => handleInputChange(e, handleInputChange)}
            //readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'title') !== undefined}
            />
            <Form.TextArea id='form-input-description'
                label={'Opis'}
                placeholder={'Opis'}
                autoFocus control={TextareaAutosize} rows={2}
                name='description'
                value={wspolpracaLocal.description || ''}
                onChange={e => handleInputChange(e)}
                //required={!readOnly}
                //readOnly={readOnly}
            //error={invalidFields.find(o => o.name === 'zrodlo_finansowania') !== undefined}
            />

                {/*{errors.lastName && <p className="text-error">Please check the Last Name</p>} */}
                <Button type='button' onClick={handleZachowaj} disabled={!canSave}>Zachowaj</Button>
                <Button type='button' onClick={handleAnuluj} >Anuluj</Button>
            </fieldset>
        </div>
    )
}

export default OrgWspolpracaEdit