import { userService } from '../user/user.service.js';
import client from '../../lib/client.js'
import { modules, pathToApi } from '../../lib/connectionConst'
import _ from 'lodash'

class OrganizationApi {
    static organizationId = (organization) => {
        return _.last(organization._links.self.href.split("/"))
    }

    static isNew = (organization) => {
        return !organization.updateTimestamp;
    }

    static canEdit = (organization) => {
        let loggedUser = userService.loggedUser();
        return userService.isAdmin() || userService.isAuthorizing()
            || (loggedUser && loggedUser.id === organization.userOwnerId)
            || (loggedUser && loggedUser.id === organization.userCreateId)
            || OrganizationApi.isNew(organization);
    }

    static checkGeoLocationOnCannotFind = (organization, errorHandler) => {
        if (OrganizationApi.canEdit(organization)) {
            client(pathToApi(modules.ORGANIZATION_GEO_SEARCHES) +`/${OrganizationApi.organizationId(organization)}`)
                .then(
                    response => { },
                    error => {
                        if (error.status.code === 404) {
                            errorHandler(error);
                        }
                    }
                )
        }
    }

    /**
     * Tworzy link mailto dla 'Send a request to change the data'
     * @param {Object} organization organizacja
     * @param {string} email e-mail adresata (właściciela danych organizacji)
     * @returns {string} tekst do wstawienia do atrybutu href znacznika <a>
     */
    static mailToHref = (organization, email) => {
        //https://e-mailer.link/en/
        let loggedUser = userService.loggedUser()
        const userInfo =
            loggedUser.lastName && loggedUser.firstName
                ?
                loggedUser.lastName + ' ' + loggedUser.firstName
                :
                loggedUser.login
        const orgId = OrganizationApi.organizationId(organization)
        const orgName = organization.nameEnglish
        return "mailto:" + email
            + "?subject=(SynPro)%20Data%20change%20"
            + "request&body=Dear%20Madam%20%2F%20Sir%2C%0AIn%20connection%20with%20the%20need%20to%20update%20the%20organization's%20data"
            + "%2C%20I%20am%20asking%20for%20permission%20to%20modify%20this%20entry.%0AThe%20change%20of%20rights%20may%20take%20place%20by%20"
            + "changing%20the%20owner%20of%20the%20data%20in%20the%20general%20data%20tab%20of%20a%20given%20organization.%0A%0A"
            + "Organization%3A%20" + orgName
            + "%0ALink%20to%20organization%3A%20https%3A%2F%2Fsynpro.e-science.pl%2Forganizations%2F" + orgId + "%2Fauthrequired"
            + "%0AUser%20to%20set%3A%20" + userInfo + "%0A%0A"
            + "I%20am%20asking%20for%20feedback%20in%20the%20case%20of%20authorization%20or%20if%20other%20additional%20explanations%20are%20expected."
            + "%0A%0ARegards%2C"
    }

    static entityTypeByCode = code => {
        for (var i in entityTypes) {
            var entity = entityTypes[i];
            //console.log("update organization set entity_type = '" + entity.value +"' where entity_type = '" + entity.text +"';");
            if (entity.value === code) return entity.text;
        }
        return code;
    }
}

const entityTypes = [
    {
        "value": "1",
        "text": "Industry SME <= 10"
    },
    {
        "value": "2",
        "text": "Industry SME 11-49"
    },
    {
        "value": "3",
        "text": "Industry SME 50-249"
    },
    {
        "value": "4",
        "text": "Industry 250- 499"
    },
    {
        "value": "5",
        "text": "Industry > 500"
    },
    {
        "value": "6",
        "text": "Industry MNE > 500"
    },
    {
        "value": "7",
        "text": "R&D Institution"
    },
    {
        "value": "8",
        "text": "University"
    },
    {
        "value": "9",
        "text": "Inventor"
    },
    {
        "value": "10",
        "text": "Startup (SpinOut, SpinOff)"
    },
    {
        "value": "11",
        "text": "Other"
    }
];

export default OrganizationApi