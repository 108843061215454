export const modules = {
    ORGANIZATION: 'organization',
    ORGANIZATION_GEO_SEARCHES: 'organizationGeoSearches',
    INFRASTRUCTURE: 'infrastructure',
    INFRASTRUCTURE_REQUESTS: 'infrastructureRequests',
    PROJECT: 'project',
    USER: 'user',
    USER_REST: 'userRest',
}

export function pathToApi(module) {
    switch(module) {
        case modules.ORGANIZATION: return '/api/organizations'
        case modules.ORGANIZATION_GEO_SEARCHES: return '/api/organizationGeoSearches'
        case modules.INFRASTRUCTURE: return process.env.REACT_APP_PATH_TO_API_INFRASTRUCTURE
        case modules.INFRASTRUCTURE_REQUESTS: return process.env.REACT_APP_PATH_TO_API_INFRASTRUCTURE_REQUESTS
        case modules.PROJECT:  return '/api/projects'
        ///api/projectPartners
        case modules.USER: return '/api/users'
        case modules.USER_REST: return '/api/rest/users'
        // 
    }
}