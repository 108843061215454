import { createSlice } from '@reduxjs/toolkit'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// const initialState = {
//     pracownicy: [],
// }

// export const pracownicyPwrSlice = createSlice({
//     name: 'pracownicyPwr',
//     initialState,
//     reducers: {
//     },
// })

//export const { added, deleted, edited } = pracownicyPwrSlice.actions
//export default pracownicyPwrSlice.reducer


export const permisesApi = createApi({
    reducerPath: 'permisesApi',
    baseQuery: fetchBaseQuery({ baseUrl: '/api/rest/' }),
    endpoints: (builder) => ({
        getPermises: builder.query({
            query: (page) => `premises?page=${page}`,
        }),
        getPermisesAll: builder.query({
            query: ({ sort ='rank%2Cdesc' }) => `premises-all?sort=${sort}`,
        }),
        searchPermises: builder.query({
            query: (searchtext) => `premises-search?searchtext=${searchtext}`,
        }),
        getPermisesOne: builder.query({
            query: (id) => `premises-one/${id}`,
        }),
        //premises-process-url
        permisesProcessUrl: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (url) => ({
                url: `premises-process-url`,
                method: 'POST',
                body: url,
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['Post'],
            // onQueryStarted is useful for optimistic updates
            // The 2nd parameter is the destructured `MutationLifecycleApi`
            async onQueryStarted(
                arg,
                { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
            ) { },
            // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                }
            ) { },
        }),
        updatePermisesPatch: builder.mutation({
            // note: an optional `queryFn` may be used in place of `query`
            query: (patch) => ({
                url: `premises-one/${patch.id}`,
                method: 'PATCH',
                body: patch,
            }),
            // Pick out data and prevent nested properties in a hook or selector
            transformResponse: (response, meta, arg) => response.data,
            invalidatesTags: ['Post'],
            // onQueryStarted is useful for optimistic updates
            // The 2nd parameter is the destructured `MutationLifecycleApi`
            async onQueryStarted(
                arg,
                { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }
            ) { },
            // The 2nd parameter is the destructured `MutationCacheLifecycleApi`
            async onCacheEntryAdded(
                arg,
                {
                    dispatch,
                    getState,
                    extra,
                    requestId,
                    cacheEntryRemoved,
                    cacheDataLoaded,
                    getCacheEntry,
                }
            ) { },
        }),

    }),
})

export default permisesApi
export const { useGetPermisesQuery, useGetPermisesAllQuery, useSearchPermisesQuery, useGetPermisesOneQuery,
    useUpdatePermisesPatchMutation, usePermisesProcessUrlMutation } = permisesApi
