import ReactDOM from 'react-dom'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Container } from 'semantic-ui-react'
import MainContent from './components/MainContent.js'
import HomePage from './components/HomePage.js'
import ProjectList from './components/project/ProjectList'
import OrganizationList from './components/organization/OrganizationList'
import MapComponent from './components/map/MapComponent.js'
import UserList from './components/user/UserList'
import { ForgotPasswordForm } from './components/user/ForgotPassword'
import { ResetPasswordForm } from './components/user/ResetPassword'
import { RegisterNewAccount } from './components/user/RegisterNewAccount';
import { ChangePasswordForm } from './components/user/ChangePasswordForm';
import { MatchmakingResults } from './components/matchmaking/MatchmakingResults'
import { MatchmakingDataSource } from './components/matchmaking/MatchmakingDataSource'
import Analysis from './components/analysis/Analysis'
import Infrastructure from './components/infrastructure/Infrastructure';
import InfrastructureRequestList from './components/infrastructure/request/InfrastructureRequestList';
import PracownicyRoute from './components/pracownicy/PracownicyRoute';
import JednostkiRoute from './components/jednostki/JednostkiRoute'
import InfrastrukturaRoute from './components/infrastruktura/InfrastrukturaRoute'
import PwrLogin from './components/PwrLogin'
import SuccessLogin from './components/SuccessLogin'
import { PrivateRoute } from './components/PrivateRoute'
import { CounterConsumer } from './redux/CounterConsumer'
import PremisesRoute from './components/premises/PremisesRoute'

const AppRouter = (props) => {
    const {setLoggedUser} = props
    const parsedUrl = new URL(window.location.href)
    //this.user_token = parsedUrl.searchParams.get("user_token")
    const tokenInQuery = parsedUrl.searchParams.get("user_token")

    return (
        <Switch>
            {
                tokenInQuery && <Redirect to="/infrastructures" />
            }
            <Route path="/" exact component={HomePage}></Route>
            <Route path="/projects">
                <PrivateRoute setLoggedUser={setLoggedUser} >
                    <MainContent><ProjectList /></MainContent>
                </PrivateRoute>
            </Route>
            <Route path="/organizations">
                <PrivateRoute setLoggedUser={setLoggedUser} >
                    <MainContent><OrganizationList /></MainContent>
                </PrivateRoute>
            </Route>
            <Route path="/map" render={(props) => <MainContent><MapComponent {...props} /></MainContent>}></Route>
            <Route path="/register_new_account" render={props => <MainContent><RegisterNewAccount {...props} /></MainContent>}></Route>
            <Route path="/forgot-password" render={props => <MainContent><ForgotPasswordForm {...props} /></MainContent>}></Route>
            <Route path="/reset-password" render={props => <MainContent><ResetPasswordForm {...props} /></MainContent>}></Route>
            <Route path="/change_password" render={props => <MainContent><ChangePasswordForm {...props} /></MainContent>}></Route>
            <Route path="/matchmaking_results" render={(props) => <MainContent><MatchmakingResults {...props} /></MainContent>}></Route>
            <Route path="/matchmaking_data_source" render={(props) => <MainContent><MatchmakingDataSource {...props} /></MainContent>}></Route>
            <Route path="/users" render={(props) => <MainContent><UserList {...props} /></MainContent>}></Route>
            <Route path="/404" component={NotFound} />
            {/* <Route path="/loginForm" render={(props) => <MainContent><LoginPage {...props} /></MainContent>}></Route> */}
            <Route path="/analysis" render={(props) => <MainContent><Analysis {...props} /></MainContent>}></Route>
            <Route path="/infrastructures">
                <PrivateRoute setLoggedUser={setLoggedUser} >
                    <MainContent><Infrastructure /></MainContent>
                </PrivateRoute>
            </Route>
            <Route path="/infrastructure-requests"><MainContent><InfrastructureRequestList /></MainContent></Route>
            <Route path="/pracownicy">
                <PrivateRoute setLoggedUser={setLoggedUser} >
                    <PracownicyRoute />
                </PrivateRoute>
            </Route>
            <Route path="/jednostki">
                <PrivateRoute setLoggedUser={setLoggedUser} >
                    <JednostkiRoute />
                </PrivateRoute>
            </Route>
            <Route path="/infrastruktury">
                <PrivateRoute setLoggedUser={setLoggedUser} >
                    <InfrastrukturaRoute />
                </PrivateRoute>
            </Route>
            <Route path="/pwr-login"><MainContent><PwrLogin setLoggedUser={setLoggedUser} /></MainContent></Route>
            <Route path="/success-login">
                <MainContent><SuccessLogin setLoggedUser={setLoggedUser} /></MainContent>
            </Route>
            <Route path="/counter-consumer"><CounterConsumer /></Route>
            <Route path="/premises"><PremisesRoute /></Route>
            

            <Redirect to="/404" />
        </Switch>
)}

const NotFound = () => (
    <MainContent>
        <Container textAlign='center' style={{ fontSize: '2em' }}>
            Page not found
        </Container>
    </MainContent>
)

export default AppRouter