import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    listaWspolpracy: [],
    idDeleted: [],
}

export const orgWspolpracaSlice = createSlice({
    name: 'orgWspolpraca',
    initialState,
    reducers: {
        added: (state, action) => {
            console.log('orgWspolpracaSlice.added action:', action)
            const generateId = () => {
                const minId = Math.min(...state.listaWspolpracy.map(wsp => wsp.id))
                if (minId < 0) return minId - 1
                return -1
            }
            const wspolpraca = action.payload
            if (!wspolpraca.id) wspolpraca.id = generateId()
            state.listaWspolpracy.push(wspolpraca)
        },
        deleted: (state, action) => {
            //console.log('orgWspolpracaSlice.deleted action:', action)
            const idToDelete = Number.isInteger(action.payload) ? action.payload : action.payload.id
            if (!Number.isInteger(idToDelete)) {
                throw 'orgWspolpracaSlice(deleted): wrong id!';
            }
            //console.log('orgWspolpracaSlice.deleted(' + idToDelete+')')
            state.listaWspolpracy = state.listaWspolpracy.filter(wsp => wsp.id !== idToDelete)
            if (idToDelete > 0) state.idDeleted.push(idToDelete)
        },
        edited: (state, action) => {

            state.listaWspolpracy = state.listaWspolpracy.map(wsp => {
                return wsp.id == action.payload.id ? action.payload : wsp
            })
        },
    },
})

// Action creators are generated for each case reducer function
export const { added, deleted, edited } = orgWspolpracaSlice.actions

export default orgWspolpracaSlice.reducer